import { Table, Spin, Tooltip, Select, Tag, Typography, Row, Button, Col, Input, Space, Checkbox, Tabs } from "antd";
import "../Client/table.css"
import PaymentRequests from "./PaymentRequests";
import PartiallyPaid from "./PartiallyPaid";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function PaymentInvoiceListing() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    const [key, setKey] = useState("");

    const handleTabUrl = (tab: string) => {
        setKey(tab.toString());
        navigate(`?tab=${tab}`)
    }
    useEffect(() => {
        setKey(searchParams.get("tab") || "1");
    }, []);
    return (
        <div className="parent-container">
            <div className="container-layout">
                <Row justify="space-between" align="top" style={{ marginBottom: 20 }}>
                    <Col>
                        <Typography style={{ fontSize: 20, fontWeight: 700 }}>Payment and Invoices</Typography>
                    </Col>
                </Row>
                <Tabs defaultActiveKey={"1"} activeKey={key} onTabClick={(e) => handleTabUrl(e)} items={[{
                    key: '1',
                    label: 'Payment Request',
                    children: <PaymentRequests/>
                },
                {
                    key: '2',
                    label: 'Partially Paid',
                    children: <PartiallyPaid />,
                },]} />
            </div>
        </div>
    );
}
