import { Button, Modal, Spin, Typography } from "antd";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "blue", marginLeft: 6 }} spin />;



interface ModalProps {
    fileData : any;
    showModal: boolean;
    loader: boolean;
    setShowModal: (showModal: boolean) => void;
    supportedFormat : boolean;
    handler: (url: string) => void;
}

const CamViewModal: React.FC<ModalProps> = ({ fileData, showModal, loader, setShowModal, supportedFormat, handler }) => {
    return (
        <div>
            <Modal
                title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{fileData?.fileName}</Typography>}
                centered
                open={showModal}
                onCancel={() => setShowModal(false)}
                afterClose={() => {}}
                className="preview-modal"
                width={900}
                footer={<>
                        <Button
                            ghost
                            danger
                            onClick={_ => {setShowModal(false);}}
                            className="button-override danger"
                            icon={<CrossIcon />}
                            >
                            Cancel
                        </Button>
                        <Button
                            ghost
                            type="primary"
                            icon={loader ? <Spin indicator={antIcon} /> : <DownloadIcon />}
                            onClick={_=> !loader && handler(fileData?.url)}
                            style={{marginLeft: 10}}
                            className="button-override primary"
                            >
                            Download CAM
                        </Button>
                </>}
            >
            {
                supportedFormat ? 
                    <iframe
                    title={fileData?.fileName}
                    src={fileData?.url}
                    style={{ width: "100%", height: "60vh", border: "none" , marginTop : '20px'}}
                    />
                :
                    <div style={{color : 'grey', fontWeight : 500, fontSize : 20, paddingTop : 40, paddingLeft : 200, paddingBottom : 40, border: '1px solid black', borderRadius: '7px'}}>
                        Preview not supported for this file format
                    </div>
            }

            </Modal>
        </div>
    );
}

export default CamViewModal;