import { Card, Col, Divider, Empty, Modal, Row, Tag, Typography } from "antd"
import dayjs from "dayjs";
import React, { useEffect } from "react"
import ReadMoreText from "../../Client/ReadMoreText";

interface VersionHistoryProps {
    data: any;
    showVersionHistory: boolean
    setShowVersionHistory: (showVersionHistory: boolean) => void;
}

const VersionHistory: React.FC<VersionHistoryProps> = ({ data, showVersionHistory, setShowVersionHistory }) => {
    // let version: any = data;
    // useEffect(() => {
    //     version = data.pop()
    // }, [data]);

    return (
        <Modal
        title={<div style={{backgroundColor: "#F6FAFF"}}>Additional Details Version History</div>}
        width={data.length !== 0 ? 1200 : 600}
        footer={null}
        centered
        styles={{
            content: {
                background: "#F6FAFF",
            },
            body: {
                background: "#F6FAFF",
                margin: 0,
                padding: 0
            }
        }}
        onCancel={() => setShowVersionHistory(false)}
        closable={true}
        destroyOnClose
        open={showVersionHistory}
        style={{height: 500, overflow: "scroll"}}
        >
        {
            data.length === 0
            ? <Empty description={"No version history found!"} style={{padding: 80}} />
            : data?.map((e: any, i: any) => {
                return <>
                <Card bordered={false} style={{marginBottom: 10}}>
                <span style={{fontWeight: 500, fontSize: 12}}>
                    <span>{dayjs(e?.updated_at).format("DD MMM YYYY hh:mm a")}</span>
                    <Divider type="vertical"/>
                    <span>Updated by: {JSON.parse(e?.updated_by)?.name}</span>
                </span>
                <Divider style={{margin: "10px 0"}}/>

                        <Row style={{ marginBottom: 20 }} gutter={[0, 12]}>
                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Ownership Type</Typography.Text>
                                    {e?.ownership_type_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.ownership_type_mod ? "#FFA800" : "black"}}>{e?.ownership_type || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>No. of Vehicle</Typography.Text>
                                    {e?.number_of_vehicles_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.number_of_vehicles_mod ? "#FFA800" : "black"}}>{e?.number_of_vehicles || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Color</Typography.Text>
                                    {e?.color_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.color_mod ? "#FFA800" : "black"}}>{e?.color || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Battery Capacity</Typography.Text>
                                    {e?.battery_capacity_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.battery_capacity_mod ? "#FFA800" : "black"}}>{e?.battery_capacity ? e?.battery_capacity + "kWh" : "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>VTS</Typography.Text>
                                    {e?.vts_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.vts_mod ? "#FFA800" : "black"}}>{e?.vts || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>VTS Remark</Typography.Text>
                                    {e?.vts_remarks_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <ReadMoreText changed={false} text={e?.vts_remarks || "NA"} />
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>User City</Typography.Text>
                                    {e?.user_city_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.user_city_mod ? "#FFA800" : "black"}}>{e?.user_city || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Registration City</Typography.Text>
                                    {e?.registration_city_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.registration_city_mod ? "#FFA800" : "black"}}>{e?.registration_city || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Registration State</Typography.Text>
                                    {e?.registration_state_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.registration_state_mod ? "#FFA800" : "black"}}>{e?.registration_state || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Payment Mode</Typography.Text>
                                    {e?.payment_mode_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.payment_mode_mod ? "#FFA800" : "black"}}>{e?.payment_mode || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Billing Frequency</Typography.Text>
                                    {e?.billing_frequency_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.billing_frequency_mod ? "#FFA800" : "black"}}>{e?.billing_frequency || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Insurance</Typography.Text>
                                    {e?.insurance_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.insurance_mod ? "#FFA800" : "black"}}>{e?.insurance || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Insurance Remarks</Typography.Text>
                                    {e?.insurance_remarks_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <ReadMoreText changed={false} text={e?.insurance_remarks || "NA"} />
                                    {/* <Typography.Text style={{color: e?.insurance_remarks_mod ? "#FFA800" : "black"}}>{e?.insurance_remarks}</Typography.Text> */}
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Payment Due Date</Typography.Text>
                                    {e?.payment_due_date_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.payment_due_date_mod ? "#FFA800" : "black"}}>{e?.payment_due_date || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Security Deposit Type</Typography.Text>
                                    {e?.security_deposit_type_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.security_deposit_type_mod ? "#FFA800" : "black"}}>{e?.security_deposit_type || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Security Deposit Value</Typography.Text>
                                    {e?.security_deposit_value_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.security_deposit_value_mod ? "#FFA800" : "black"}}>{e?.security_deposit_value || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Processing Fees</Typography.Text>
                                    {e?.processing_fees_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <Typography.Text style={{color: e?.processing_fees_mod ? "#FFA800" : "black"}}>{e?.processing_fees || "NA"}</Typography.Text>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div>
                                    <Typography.Text style={{fontWeight: 500, fontSize: 12}}>Remarks</Typography.Text>
                                    {e?.remarks_mod && <Tag style={{marginLeft: 8}} color="orange">Changed</Tag>}
                                </div>
                                <div style={{ marginTop: 4 }}>
                                    <ReadMoreText changed={false} text={e?.remark || "NA"} />
                                    {/* <Typography.Text style={{color: e?.remarks_mod ? "#FFA800" : "black"}}>{e?.remark}</Typography.Text> */}
                                </div>
                            </Col>

                        </Row>
                </Card>
            </>
        })
        }
    </Modal>
    )
}

export default VersionHistory;