import { Card, Progress, Typography } from "antd";
import { ReactComponent as TickMarkIcon } from "../../SVGs/tick_mark_icon.svg";
import { ReactComponent as FleetIcon } from '../../SVGs/fleets_icon.svg';
import dayjs from "dayjs";
import currencyFormatter from "../../../../utils/NumberFormater";
import { useEffect, useState } from "react";
import _ from "lodash";

interface QuoteInfoProps {
    clientData: any;
    quoteData: any;
}

const QuoteInfo: React.FC<QuoteInfoProps> = ({ clientData, quoteData }) => {
    const [utilized, setUtilized] = useState<any>(0)

    useEffect(() => {
        let progress = (!_.isNil(clientData?.creditLimit) && !_.isNil(clientData?.utilisedCreditLimit)) ? (clientData?.creditLimit > clientData?.utilisedCreditLimit) ? ((clientData?.utilisedCreditLimit / clientData?.creditLimit) * 100) : 100 : 0;
        setUtilized(progress)
    }, [clientData]);

    return ( <Card bordered={false} style={{margin: "20px 0"}}>
        <div style={{position: "absolute", top: 0, right: 0, fontSize: 10, backgroundColor: "#02B51F", color: "white", padding: "5px 10px", borderRadius: "0px 8px 0px 8px"}}>
            {`Validity: ${clientData?.approvedValidityTill ? dayjs(clientData?.approvedValidityTill).format("DD MMM YYYY") : "NA"}`}
        </div>
        <Typography style={{color: "#616161", fontWeight: 500}}>Contract Details</Typography>
        <div style={{display: "flex"}}>
            <div style={{ width: "50%", paddingRight: 50, borderRight: "1px solid #2C66E37A" }}>
                <Progress strokeLinecap="butt" strokeColor={{'0%': '#108ee9','100%': '#87d068',}} percent={utilized} />
                <div style={{display: "flex", justifyContent: "space-between", marginTop: 20}}>
                    <div>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>Utilized Credit Limit</Typography>
                        <Typography style={{fontSize: 20, fontWeight: 700, color: "#404040"}}>{"₹" + currencyFormatter(clientData?.utilisedCreditLimit) || 0}</Typography>
                    </div>
                    <div>
                        <Typography style={{fontSize: 12, fontWeight: 500, color: "#616161"}}>Approved Credit Limit</Typography>
                        <Typography style={{fontSize: 20, fontWeight: 700, color: "#404040"}}>{"₹" + currencyFormatter(clientData?.creditLimit) || 0}</Typography>
                    </div>
                </div>
            </div>
            <div style={{ width: "50%", paddingLeft: 50 }}>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20}}>
                    <div><span style={{ backgroundColor: "#E8F1FF", padding: 8, borderRadius: 99 }}><TickMarkIcon style={{color: "#2c66e3"}} /></span> <span style={{marginLeft: 5, color: "#616161", fontWeight: 500, fontSize: 16}}>Approved No. of Fleet</span></div>
                    <div style={{color: "#616161", fontWeight: 500, fontSize: 16}}>{`${clientData?.approvedNumberOfFleets || 0}`}</div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between", marginBottom: 20}}>
                    <div><span style={{ backgroundColor: "#E8F1FF", padding: 8, borderRadius: 99 }}><FleetIcon /></span> <span style={{marginLeft: 5, color: "#616161", fontWeight: 500, fontSize: 16}}>Utilized No. of Fleets</span></div>
                    <div style={{color: "#616161", fontWeight: 500, fontSize: 16}}>{clientData?.utilisedNumberOfFleets || 0}</div>
                </div>
            </div>
        </div>
    </Card> );
}

export default QuoteInfo;