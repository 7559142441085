import { Button, Card, Divider, Form, Progress, Row, Select, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../SVGs/edit_icon.svg";
import { getRequest, putRequest } from "../../../../utils/handler/apiHandler";
import QuotePriceDetails from "./QuoteDetailsForm";
import type { TabsProps } from 'antd';
import QuoteInfo from "./QuoteInfo";
import { useNavigate } from "react-router-dom";
import CompleteQuoteDetailsSection from "./CompleteQuoteDetailsSection";
import ClientQuoteList from "./ClientQuoteList";
import currencyFormatter from "../../../../utils/NumberFormater";

interface CompleteQuoteDetailsProps {
    isPayments: boolean;
    isClientQuote: boolean;
    data: any;
    additionalDetailsLog: any;
    loader: boolean;
    quoteData : any;
    requestDetails: any;
    user: any;
    allowedActionsForSampleQuote: Set<string>;
    clientQuoteId: number
}

const TabPane = Tabs.TabPane;
const { Title } = Typography;
const RUPEE_SYMBOL = "₹ ";

const CompleteQuoteDetails: React.FC<CompleteQuoteDetailsProps> = ({ isPayments, isClientQuote, additionalDetailsLog, data, loader, quoteData, requestDetails, user,allowedActionsForSampleQuote,clientQuoteId }) => {
    const [form] = Form.useForm();
    const [editable, setEditable] = useState(false);
    const [clientData, setClientData] = useState<any>([]);
    const query = new URLSearchParams(location.search);
    const initialTab = query.get('tab') || '1';
    const [activeKey, setActiveKey] = useState(initialTab || '1');

    useEffect(() => {
        quoteData?.prospectId && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/clientOnBoarding/searchClientByProspectId?prospectId=${quoteData?.prospectId}`).then(res => {
            setClientData(res.data);
        })
    }, []);
    useEffect(() => {
        form.setFieldsValue({exShowroom : quoteData?.exShowroom, 
            baseAmt : quoteData?.basePrice,
            gstAmt : quoteData?.gstOnVehicle,
            fameSubsidy : quoteData?.fameSubsidy,
            stateSubsidy : quoteData?.stateSubsidy,
            discount: quoteData?.discount,
            roadTax : quoteData?.roadTaxTotal,
            insuranceAmt : quoteData?.insuranceAmountTotal,
            firstYrInsuranceAmt : quoteData?.projectedPremium && JSON.parse(quoteData?.projectedPremium)['1'],
            managementFee : quoteData?.managementFees,
            tcs : quoteData?.tcs,
            rvPercentage : quoteData?.rvPercentage,
            costOfFund : quoteData?.costOfFundPercentage,
            marginRate : quoteData?.offerRatePercentage,
            quoteRemarks : quoteData?.quoteRemarks,
            excessMileageCharge : quoteData?.excessMileageChargePerKm,
            fabricationAmountTotal : quoteData?.fabricationAmountTotal,
            fabricationGst: quoteData?.fabricationGst,
            fabricationBaseAmountTotal: quoteData?.fabricationBaseAmountTotal,
            otherAccessoriesDescription:quoteData?.otherAccessoriesDescription,
        });
    }, [editable == true]);

    return ( 
        <React.Fragment>
            <QuoteInfo clientData={clientData} quoteData={quoteData} />
            <Card bordered={false}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <Typography style={{fontWeight: 500, color: "#404040"}}>Lease Rental Per Vehicle</Typography>
                <span style={{fontWeight: 600, fontSize: 16, color: "#2C66E3"}}>₹ {currencyFormatter(quoteData?.leaseRentWithTaxInMonth)}</span>
                </div>
            </Card> 

            {(isClientQuote || isPayments) ? <Tabs 
                activeKey={activeKey}
                onChange={setActiveKey}
                className="tab"
                style={{margin:'20px'}}
            >
                <TabPane tab={<span className="tab-heading">Details</span>} key="1">
                    <CompleteQuoteDetailsSection isPayments={isPayments} isClientQuote={isClientQuote} additionalDetailsLog={additionalDetailsLog} form={form} loader={loader} quoteData={quoteData} allowedActionsForSampleQuote={allowedActionsForSampleQuote} clientData={data}/>
                </TabPane>
                <TabPane tab={<span className="tab-heading">Client Quote</span>} key="2">
                    <ClientQuoteList clientQuoteId={clientQuoteId} />
                </TabPane>
            </Tabs>
            : <CompleteQuoteDetailsSection isPayments={isPayments} isClientQuote={isClientQuote} additionalDetailsLog={additionalDetailsLog} form={form} loader={loader} quoteData={quoteData} allowedActionsForSampleQuote={allowedActionsForSampleQuote} clientData={data}/>}
        </React.Fragment>
     );
}

export default CompleteQuoteDetails;