import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Typography, Input } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee } from '../formUtils';

interface EquityProps {
    FYCount: number;
    form: any; 
    colSpan: number;
    labelColSpan: number;
    updateTotalCnt: number;
    viewModeActive: boolean;
    CYACount : number;
    setCYACount : (count : number) => void;
    CYADateRanges : any[];
    setCYADateRanges : (range : any) => void;
}

const EquityComponent: React.FC<EquityProps> = ({ FYCount, form, colSpan, updateTotalCnt, viewModeActive, labelColSpan, CYACount, setCYACount, CYADateRanges, setCYADateRanges }) => {
    const [totalEquity, setTotalEquity] = useState<number[]>(Array(FYCount).fill(0));
    const [collapseEquityFields, setCollapseEquityFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if (values.totalEquity)
            setTotalEquity(values.totalEquity);
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        const updatedTotalEquity = values.shareCapital.map((item: number, i: number) => Number(item) + Number(values.reserveAndSurplus[i]));
        form.setFieldsValue({ totalEquity: updatedTotalEquity });
        setTotalEquity(updatedTotalEquity);
    };
    
    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '10px', paddingTop: '10px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}
            >
                <Col span={labelColSpan}>
                    <span onClick={() => setCollapseEquityFields(oldState => !oldState)}>
                        {collapseEquityFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>Total Equity</span>
                </Col>
                {totalEquity.map((value, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}> 
                            <span>{`${INR_SYMBOL} ${formatIndianRupee(parseFloat(value.toFixed(2)))}`}</span>
                        </Typography>
                    </Col>
                ))}
            </Row>
            {!collapseEquityFields && (
                <>
                    <Row gutter={16} style={{  paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Share Capital</p></Col>
                        {Array.from({ length: CYACount + FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{paddingLeft: '10px'}}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['shareCapital', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['shareCapital', ind]}
                                        rules={[{ required: true, message: 'Please enter Share Capital value' }]}
                                    >
                                        <Input
                                            type="number"
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{  paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}> Reserves & Surplus </p></Col>
                        {Array.from({ length: CYACount + FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{paddingLeft: '10px'}}>
                                         <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['reserveAndSurplus', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['reserveAndSurplus', ind]}
                                        rules={[{ required: true, message: 'Please enter Reserve and Surplus value' }]}
                                    >
                                        <Input
                                            type="number"
                                            onChange={handleFieldChange}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default EquityComponent;
