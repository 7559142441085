import React, { useEffect } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { formatPercentageNumber, formatRatioNumber } from '../formUtils';

interface OtherRatioAnalysisProps {
    FYCount: number;
    form: any;
    colSpan: number;
    labelColSpan: number;
    viewModeActive: boolean;
    assessmentYearWithDateAndMonth : string[];
}

const OtherRatioAnalysisComponent: React.FC<OtherRatioAnalysisProps> = ({ FYCount, form, colSpan, viewModeActive, 
        labelColSpan, assessmentYearWithDateAndMonth }) => {
    // console.log(`netFixedAssets=%o , %o`,form.getFieldValue(['netFixedAssets', 0]),
    // form.getFieldValue(['netFixedAssets', 1]));
    return (
        <>
            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px' }}>Revenue Growth (%)</Typography>
                </Col>
                {Array.from({ length: assessmentYearWithDateAndMonth.length }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ paddingLeft: '10px' }}>
                            { ind === FYCount-1 ? 'NA' : formatPercentageNumber(form.getFieldValue(['revenueGrowthPercentage', ind])) }
                        </Typography>
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px' }}>Net Profit</Typography>
                </Col>
                {Array.from({ length: assessmentYearWithDateAndMonth.length }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ paddingLeft: '10px' }}>
                            { ind === FYCount-1 ? 'NA' : formatPercentageNumber(form.getFieldValue(['netProfit', ind])) }
                        </Typography>
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px' }}>Inventory Turnover Ratio</Typography>
                </Col>
                {Array.from({ length: assessmentYearWithDateAndMonth.length }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ paddingLeft: '10px' }}>{form.getFieldValue(['inventoryTurnoverRatio', ind])}</Typography>
                        ) : (
                            <Form.Item
                                name={['inventoryTurnoverRatio', ind]}
                                rules={[{ required: true, message: 'Please enter Inventory Turnover Ratio value' }]}
                            >
                                <Input  type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px' }}>Trade Receivable Turnover Ratio</Typography>
                </Col>
                {Array.from({ length: assessmentYearWithDateAndMonth.length }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ paddingLeft: '10px' }}>{form.getFieldValue(['tradeReceivableTurnoverRatio', ind])}</Typography>
                        ) : (
                            <Form.Item
                                name={['tradeReceivableTurnoverRatio', ind]}
                                rules={[{ required: true, message: 'Please enter Trade Receivable Turnover Ratio value' }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px' }}>Trade Payable Turnover Ratio</Typography>
                </Col>
                {Array.from({ length: assessmentYearWithDateAndMonth.length }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        {viewModeActive ? (
                            <Typography style={{ paddingLeft: '10px' }}>{form.getFieldValue(['tradePayableTurnoverRatio', ind])}</Typography>
                        ) : (
                            <Form.Item
                                name={['tradePayableTurnoverRatio', ind]}
                                rules={[{ required: true, message: 'Please enter Trade Payable Turnover Ratio value' }]}
                            >
                                <Input type="number" />
                            </Form.Item>
                        )}
                    </Col>
                ))}
            </Row>

            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}>
                <Col span={labelColSpan}>
                    <Typography style={{ marginLeft: '20px' }}>Sales / Net Fixed Assets</Typography>
                </Col>
                {Array.from({ length: assessmentYearWithDateAndMonth.length }, (_, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ paddingLeft: '10px' }}>
                            { formatRatioNumber(form.getFieldValue(['netFixedAssets', ind])) }
                        </Typography>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default OtherRatioAnalysisComponent;
