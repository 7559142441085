import Icon, { LeftOutlined, MinusOutlined, PlusCircleOutlined, PlusOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Select, Spin, Table, TablePaginationConfig, Tooltip, Typography } from "antd";
import { ColumnsType, SorterResult } from "antd/es/table/interface";
import { AlertListType as AlertListType } from "./types/ServiceList";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handlePageChange, handlePageSizeChange } from "../Prospect/utils/paginationFunctions";
import FilterDropdown from "../Client/FilterDropDown";
import CustomSelectFilter from "../Prospect/utils/CustomSelectFilter";
import UnionFilterIcon from "../SVGs/UnionFilterIcon";
import { getFormattedDate } from "../../../utils/helpers/utils";
import { getRequest } from "../../../utils/handler/apiHandler";
import { ReactComponent as DownloadIcon } from '../../../utils/icons/download_icon_white.svg';
import CreateTicketModal from "./CreateTicketModal";

const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
};

const AlertList : React.FC = () => {
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultData: AlertListType[] = [];
    const [data, setData] = useState<AlertListType[]>(defaultData);
    const tableRef = useRef<HTMLDivElement>(null);
    const [tableHeight, setTableHeight] = useState<number>(600);
    const [sortOrder, setSortOrder] = useState<string>('&sort=serviceDate:desc'); // Default sort
    const [assetList, setAssetList] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [contactNumberList, setContactNumberList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [vehicleList, setVehicleList] = useState([]);
    const [alertTypeList, setAlertTypeList] = useState<any[]>([]);
    const [selectedAlertTypeList, setSelectedAlertTypeList] = useState<any[]>([]);
    const [statusList, setStatusList] = useState<any[]>([]);
    const [selectedStatusList, setSelectedStatusList] = useState<any[]>([]);
    const [showCreateTicketModal, setShowCreateTicketModal] = useState<boolean>(false);
    const FILTER_ICON_COLOR = '#1777FF';

    const filterIconAlertType = () => (
        <UnionFilterIcon fill={selectedAlertTypeList.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "10px" }} />
      );
    
    const filterIconStatus = () => (
        <UnionFilterIcon fill={selectedStatusList.length===0 ? '#2C2C2C' : FILTER_ICON_COLOR} style={{ marginLeft: "10px" }} />
      );
  

    useEffect(() => {
        fetchAllAlertData();
    }, [currentPage, pageSize, sortOrder]);

    const fetchAllAlertData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/ticket?page=${currentPage - 1}&size=${pageSize}${sortOrder ? sortOrder : ""}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                setData(res.data.data);
                if ('pageCount' in res.data) {
                    setPageCount(res.data.pageCount);
                }
                if ('totalRecord' in res.data) {
                    setTotalRecord(res.data.totalRecord);
                }
            } else {
                console.error('Error fetching Alert List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Alert data:', error);
        } finally {
            setLoading(false);
        }
    };


    const getColumns = (): ColumnsType<any> => [
        {
            title: "Asset ID",
            dataIndex: "assetNumber",
            key: "assetNumber",
            filters: assetList.map((option: any) => ({
                text: option,
                value: option,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    width={200}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/assetList?page=${page}&size=10&number=${name}`}}
                    setBusinessNameOptions={setAssetList}
                />
            ),
            width: "7%",
        },
        {
            title: "Client Name",
            dataIndex: 'clientName',
            filters: clientList.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospectNames?page=${page}&size=10&name=${name}`}}
                    setBusinessNameOptions={setClientList}
                />
            ),
            key: 'clientName',
            width: '11%',
        },
        {
            title: "Vehicle Number",
            dataIndex: 'vehicleNumber',
            filters: vehicleList.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/clientList?page=${page}&size=10&name=${name}`}}
                    setBusinessNameOptions={setVehicleList}
                />
            ),
            key: 'clientName',
            width: '9%',
        },
        {
            title: 'Alert Type',
            dataIndex: 'alertType',
            filterIcon: filterIconAlertType, 
            filterDropdown: (
              <div style={{ padding: 8, width: 180 }}>
                <CustomSelectFilter
                  itemsList={[
                    { id: '1', name: 'KM Based' },
                    { id: '1', name: 'Time Based' },
                  ]}
                  selectedItems={selectedAlertTypeList}
                  setSelectedItems={setSelectedAlertTypeList}
                  placeholder="Alert Type"
                  setMainCurrentPage={setCurrentPage}
                />
              </div>
            ),
            render: (text : number, record: any) => {
              return( <div className="table-text-style">{record?.alertType}</div> )},
            key: 'alertType',
            width: '7%',
        },

        // {
        //     title: "Contact Number",
        //     dataIndex: 'contactNumber',
        //     filters: contactNumberList.map((option: any) => ({
        //         text: option.name,
        //         value: option.id,
        //     })),
        //     filterIcon: <SearchOutlined />,
        //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
        //         <FilterDropdown
        //             setSelectedKeys={setSelectedKeys}
        //             selectedKeys={selectedKeys}
        //             confirm={confirm}
        //             clearFilters={clearFilters}
        //             filters={filters}
        //             fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/contactNumberList?page=${page}&size=10&name=${name}`}}
        //             setBusinessNameOptions={setContactNumberList}
        //         />
        //     ),
        //     key: 'contactNumber',
        //     width: '8%',
        // },
        {
            title: "Location",
            dataIndex: 'location',
            filters: cityList.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name:any, page:any)=>{return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/servicemodule/vehicle/cityList?page=${page}&size=10&name=${name}`}}
                    setBusinessNameOptions={setCityList}
                />
            ),
            key: 'location',
            width: '7%',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filterIcon: filterIconStatus, 
            filterDropdown: (
              <div style={{ padding: 8, width: 180 }}>
                <CustomSelectFilter
                  itemsList={statusList}
                  selectedItems={selectedStatusList}
                  setSelectedItems={setSelectedStatusList}
                  placeholder="Status"
                  setMainCurrentPage={setCurrentPage}
                />
              </div>
            ),
            render: (text : number, record: any) => {
              return( <div className="table-text-style">{record?.alertType}</div> )},
            key: 'status',
            width: '8%',
        },
        {
            title: "Current Odometer",
            dataIndex: "currentOdometer",
            key: "currentOdometer",
            sorter: { multiple: 2 },
            render: (text : number, record: any) => {
                return( <div className="table-text-style">{record?.currentOdometer} km</div> )},
            width: "9%",
        },
        {
            title: "Service Date",
            dataIndex: "serviceDate",
            key: "serviceDate",
            sorter: { multiple: 2 },
            render: (date: string) => date ? getFormattedDate(date) : "",
            width: "8%",
        },
        {
            title: "Action",
            key: "action",
            fixed: "right",
            render: (text, record) => (
                <Tooltip title="Create Ticket">
                    <a onClick={() => setShowCreateTicketModal(true)}>
                      <span><PlusOutlined/> Create Ticket</span>
                    </a>
                </Tooltip>
            ),
            width: "8%",
        },
    ];

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<AlertListType> | SorterResult<AlertListType>[]
    ) => {
        let sortString = '';
        let filterString = ""

        if (Array.isArray(sorter)) {
            // Handle multiple sorts
            sortString = sorter
                .filter(s => s.order) // Filter out columns without sorting
                .map(s => `${s.field}:${s.order === 'ascend' ? 'asc' : 'desc'}`) // Map each sorter to the format field:asc/desc
                .join(',');
        } else if (sorter.order) {
            // Handle single sort
            const sortField = sorter.field;
            const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
            sortString = `${sortField}:${sortOrder}`;
        }

        if (sortString && sortString !== "") {
            sortString = "&sort=" + sortString
        }
        if (filters?.legalBusinessName?.length > 0) {
            filterString = filterString+`&legalEntityIds=${encodeURIComponent(filters?.legalBusinessName)}`
        }
        if (filters?.clientId?.length > 0) {
            filterString = filterString+`&clientIds=${encodeURIComponent(filters?.clientId)}`
        }
        if (filters?.ownedBy?.length > 0) {
            filterString = filterString+`&ownerIds=${encodeURIComponent(filters?.ownedBy)}`
        }
        if (filters?.contractNumber?.length > 0) {
            filterString = filterString+`&contractNumbers=${encodeURIComponent(filters?.contractNumber)}`
        }
        
        // Set the sort order string for the API call
        setSortOrder(sortString + filterString);
    };

        return (
            <div className="parent-container">
                <div className="container-layout">
                    <Row justify="space-between" align="top" style={{ marginBottom: 20 }}>
                        <Col>
                            <Typography style={{ fontSize: 20, fontWeight: 700 }}>Alert</Typography>
                        </Col>
                        <Col>
                        <Button
                            type="primary"
                            icon={<Icon component={DownloadIcon} />}
                            onClick={(_) => setShowCreateTicketModal(true)}
                            style={{ fontWeight: 500, marginTop: 15, marginLeft: 10, height: 40}}
                        >
                            Download Data
                        </Button>
                        </Col>
                    </Row>
    
                    <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
                        <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                            <Spin spinning={loading} size="large">
                                <Table
                                    className="lev-table"
                                    columns={getColumns()}
                                    dataSource={data || []}
                                    pagination={{
                                        pageSize: pageSize,
                                        position: ["bottomRight"],
                                        showSizeChanger: false,
                                        showTotal: (total, range) => (
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '-4px' }}>
                                                <div style={{ fontSize: '1rem' }}>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>Showing</span>
                                                    <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                                        <Select.Option value="10">10</Select.Option>
                                                        <Select.Option value="20">20</Select.Option>
                                                        <Select.Option value="50">50</Select.Option>
                                                        <Select.Option value="100">100</Select.Option>
                                                    </Select>
                                                    <span style={{ fontSize: 15, fontWeight: 400 }}>per page</span>
                                                    <span style={{ fontSize: 15, fontWeight: 400, marginRight: '10px', marginLeft: '10px', color: 'grey' }}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                                    <span style={{ fontSize: 14, fontWeight: 400 }}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                                                </div>
                                            </div>
                                        ),
                                        onChange: handlePageChangeLocal,
                                        current: currentPage,
                                        total: totalRecord,
                                        itemRender: (current, type, originalElement) => {
                                            if (type === 'prev') {
                                                return <LeftOutlined style={{ marginLeft: '4rem', fontSize: 12, fontWeight: 500 }} />;
                                            }
                                            if (type === 'next') {
                                                return <RightOutlined style={{ fontSize: 12, fontWeight: 500 }} />;
                                            }
                                            if (type === 'page') {
                                                return (
                                                    <span style={{ cursor: 'pointer', fontSize: 12, fontWeight: 500 }} onClick={() => handlePageChangeLocal(current)}>
                                                        {current}
                                                    </span>
                                                );
                                            }
                                            if (type === 'jump-next' || type === 'jump-prev') {
                                                return <a style={{ color: 'black', fontSize: 12, fontWeight: 500 }}><strong> ... </strong></a>;
                                            }
                                            return originalElement;
                                        },
                                    }}
                                    onChange={handleTableChange} // Handle sort
                                    scroll={{ x: 1700, y: 0.80 * tableHeight }}
                                    size="middle"
                                // components={{
                                //     header: {
                                //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                                //     },
                                //   }}
                                />
                            </Spin>
                        </div>
                    </div>
                </div>
                <Modal width={900} footer={null}
                    centered style={{ textAlign: "center" }}
                    styles={{
                        content: {
                            background: "#F6FAFF",
                        },
                        body: {
                            background: "#F6FAFF",
                            margin: 0,
                            padding: 0
                        }
                    }}
                    onCancel={() => setShowCreateTicketModal(false)}
                    closable={true}
                    destroyOnClose
                    open={showCreateTicketModal}
                >
                    <CreateTicketModal loader = {false} acceptLoader = {false} details={null}/>
                </Modal>
            </div>

        );
}

export default AlertList;