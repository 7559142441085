import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Typography, Input } from 'antd';
import EquityComponent from './EquityComponent';
import NonCurrentLiabilitiesComponent from './NonCurrentLiabilitiesComponent';
import TotalCurrentLiabilitiesComponent from './TotalCurrentLiabilitiesComponent';
import TotalCurrentAssetsComponent from './TotalCurrentAssetsComponent';
import { ReactComponent as ArrowDown } from '../../../../utils/SVGs/ArrowDown.svg';
import { ReactComponent as ArrowRight } from '../../../../utils/SVGs/ArrowRight.svg';
import { formatFormData, formatIndianRupee, formatUpdateFormData } from '../formUtils';
import { sendFormData, sendUpdateFormData } from '../apiCalls';
import OtherFinancialSpreadingComponent from './OtherFinancialSpreadingComponent';
import OperationFinancialSpreadingComponent from './OperationFinancialSpreadingComponent';

const INR_SYMBOL: string = '\u20B9';

interface FinancialSpreadingProps {
    FYCount: number;
    calenderYear: boolean;
    creditRequestId: number;
    assessmentFormFlag: number;
    financialSpreading: any;
    submitForm: boolean;
    setSubmitForm: (flag: boolean)=>void;
    setReloadComponentCnt: (value:number) => void;
    parentAssessmentFrom: boolean;
    CYACount : number;
    setCYACount : (count : number) => void;
    assessmentYearWithDateAndMonth : string[];
    setAssessmentYearWithDateAndMonth : (AYdateAndMonth : string[]) => void;
    CYADateRanges : any[];
    setCYADateRanges : (range : any) => void;
}

const FinancialSpreading: React.FC<FinancialSpreadingProps> = ({ FYCount, calenderYear, creditRequestId, assessmentFormFlag, 
        financialSpreading, submitForm, setSubmitForm, setReloadComponentCnt, parentAssessmentFrom, CYACount, setCYACount, 
        assessmentYearWithDateAndMonth, setAssessmentYearWithDateAndMonth, CYADateRanges, setCYADateRanges }) => {
    const [collapseFinancialSpreading, setCollapseFinancialSpreading] = useState<boolean>(true);
    const [colSpan, setColSpan] = useState<number>(0);
    const [labelColSpan, setLabelColSpan] =  useState<number>(5);
    const [updateTotalCnt, setUpdateTotalCnt] = useState<number>(0);
    const [preventGetAssessmentCallCnt, setPreventGetAssessmentCallCnt] = useState<number>(0);
    const [viewModeActive, setViewModeActive] = useState<boolean>(false);
    const [form] = Form.useForm();

    const FORM_ID: number = 1;
    useEffect(()=>{
        setUpdateTotalCnt(( oldValue:number ) => oldValue+1 );
        const shareCapitalString = financialSpreading?.shareCapital;
        const shareCapitalList = shareCapitalString ? JSON.parse(shareCapitalString) : [];
        const updatedInitialValues = generateInitialValuesForFYChange(FYCount);
        const formValue = form.getFieldValue('shareCapital');
        const formValueList = Array.isArray(formValue) ? formValue : [];
        const olderInitialValuesArrayLength = formValueList.length;
        form.setFieldsValue(updatedInitialValues);
        const idealColSpan = Math.floor(24/(FYCount+CYACount+1));
        const tempLabelColSpan = Math.max(5,idealColSpan);
        setColSpan(Math.floor((24-tempLabelColSpan) / (FYCount+CYACount)));
        setLabelColSpan(tempLabelColSpan);
        if(preventGetAssessmentCallCnt>=2){
            if(CYACount+FYCount !== assessmentYearWithDateAndMonth.length){
                setAssessmentYearWithDateAndMonth(getAssessmentYearWithDateAndMonthForFYChange(calenderYear, FYCount, olderInitialValuesArrayLength));
            }
        }
        else{
            setPreventGetAssessmentCallCnt((oldCnt:number) => oldCnt+1);
            if(assessmentYearWithDateAndMonth.length === 0){
                setAssessmentYearWithDateAndMonth(getAssessmentYearWithDateAndMonth(calenderYear, FYCount));
            }
        }
    },[FYCount]);

    useEffect(()=>{
        setUpdateTotalCnt(( oldValue:number ) => oldValue+1 );
        const updatedInitialValues = generateInitialValuesForCYAChange(CYACount);
        const formValue = form.getFieldValue('shareCapital');
        const formValueList = Array.isArray(formValue) ? formValue : [];
        const olderInitialValuesArrayLength = formValueList.length;
        form.setFieldsValue(updatedInitialValues);
        const idealColSpan = Math.floor(24/(FYCount+CYACount+1));
        const tempLabelColSpan = Math.max(5,idealColSpan);
        setColSpan(Math.floor((24-tempLabelColSpan) / (FYCount+CYACount)));
        setLabelColSpan(tempLabelColSpan);
        if(preventGetAssessmentCallCnt>=2){
            if(CYACount+FYCount !== assessmentYearWithDateAndMonth.length){
                setAssessmentYearWithDateAndMonth(getAssessmentYearWithDateAndMonthForCYAChange(calenderYear, CYACount, olderInitialValuesArrayLength));
            }
        }
        else{
            setPreventGetAssessmentCallCnt((oldCnt:number) => oldCnt+1);
        }
    },[CYACount]);

    useEffect(()=>{
        if(preventGetAssessmentCallCnt>=2){
            setAssessmentYearWithDateAndMonth(getAssessmentYearWithDateAndMonthForCalenderChange(calenderYear, CYACount));
        }
    },[calenderYear]);
    console.info(`CYACount=%o`,CYACount)

    useEffect(()=>{
        setUpdateTotalCnt(( oldValue:number ) => oldValue+1 );
        form.setFieldsValue(generateInitialValues(CYACount + FYCount, parsedFinancialSpreading));
    },[financialSpreading]);

    useEffect(()=>{
        if(assessmentFormFlag && assessmentFormFlag !== FORM_ID){
            setViewModeActive(true);
        }
        else if(assessmentFormFlag && assessmentFormFlag === FORM_ID){
            setViewModeActive(false);
        }
    },[assessmentFormFlag]);

    useEffect(()=>{
        if(submitForm===true && assessmentFormFlag === FORM_ID){
            setSubmitForm(false);
            onFinish();
        }
    },[submitForm])

    const getAssessmentYearWithDateAndMonth = (calenderYear:boolean, FYCount: number)=>{
        let FYYearsDetails = null;
        // let CYAYearsDetails=  CYADateRanges.map((dates)=>`${dates.startDate} - ${dates.endDate}`)
        if( calenderYear ){
            FYYearsDetails = Array.from({ length: FYCount }, (_, ind) => (
                    `1 Jan ${2024 - ind} - 31 Dec ${2024 - ind}`
                 ));
        }
        else{
            FYYearsDetails =  Array.from({ length: FYCount }, (_, ind) => (
                    `1 Apr ${2023 - ind} - 31 Mar ${2024 - ind}`
                 ));
        }
        let res = [...FYYearsDetails];
        return res;
    }

    function getYearFromDateRange(dateRange: string|undefined) {
        // Match the year from the start of the date range using a regular expression
        const regex = /\d{1,2} \w+ (\d{4}) - \d{1,2} \w+ (\d{4})/;
        const match = dateRange?.match(regex);
        
        if (match) {
            // Extract the years
            const startYear = match[1];
            const endYear = match[2];
            
            // Return the years as an array or an object
            return [ parseInt(startYear,10), parseInt(endYear,10) ];
        } else {
            throw new Error("Invalid date range format");
        }
    }
    

    const getAssessmentYearWithDateAndMonthForFYChange = (calenderYear:boolean, FYCount: number, oldLength: number)=>{
        let oldAssessmentYearWithDateAndMonth = assessmentYearWithDateAndMonth;
        if(oldLength >= CYACount + FYCount){ // Case when FYCount is decreasing
            const res = oldAssessmentYearWithDateAndMonth.filter(( val:string, ind:number ) => ind < FYCount+CYACount );
            return res;
        }
        let FYYearsDetails;
        const years: number[] = getYearFromDateRange(assessmentYearWithDateAndMonth.at(-1));
        if( calenderYear ){
            FYYearsDetails = `1 Jan ${years[0] - 1} - 31 Dec ${years[1] - 1}`;
        }
        else{
            FYYearsDetails = `1 Apr ${years[0] - 1} - 31 Mar ${years[1] - 1}`;
        }
        let res = [...oldAssessmentYearWithDateAndMonth, FYYearsDetails];
        return res;
    }

    const getAssessmentYearWithDateAndMonthForCYAChange = (calenderYear:boolean, CYACount: number, oldLength: number)=>{
        let oldAssessmentYearWithDateAndMonth = assessmentYearWithDateAndMonth;
        if(oldLength >= CYACount + FYCount){ // Case when CYACount is decreasing
            const res = oldAssessmentYearWithDateAndMonth.filter(( val:string, ind:number ) => ind >= oldLength - FYCount - CYACount );
            return res;
        }
        let CYAYearsDetails = `${CYADateRanges[0]?.startDate} - ${CYADateRanges[0]?.endDate}`;
        let res = [ CYAYearsDetails, ...oldAssessmentYearWithDateAndMonth];
        return res;
    }

    const getAssessmentYearWithDateAndMonthForCalenderChange = (calenderYear:boolean, CYACount: number)=>{
        let oldAssessmentYearWithDateAndMonth = assessmentYearWithDateAndMonth;
        let newAssessmentYearWithDateAndMonthForFY = assessmentYearWithDateAndMonth.slice(CYACount);
        newAssessmentYearWithDateAndMonthForFY = newAssessmentYearWithDateAndMonthForFY.map((date: string) => {
            let FYYearsDetails;
            const years: number[] = getYearFromDateRange(date);
            if( calenderYear ){
                FYYearsDetails = `1 Jan ${years[0]} - 31 Dec ${years[0]}`;
            }
            else{
                FYYearsDetails = `1 Apr ${years[0]} - 31 Mar ${years[0] + 1}`
            }
            return FYYearsDetails;
        })
        
        let res = [...oldAssessmentYearWithDateAndMonth.slice(0,CYACount), ...newAssessmentYearWithDateAndMonthForFY];
        return res;
    }

    const onFinish = async () => {
        try {
            await form.validateFields();
            if(financialSpreading?.id){
                const formattedData = formatUpdateFormData(form.getFieldsValue(true), calenderYear, FYCount, CYACount , assessmentYearWithDateAndMonth);
                await sendUpdateFormData(formattedData,financialSpreading?.id, creditRequestId, parentAssessmentFrom);
                setCollapseFinancialSpreading(true);
            }
            else{
                const formattedData = formatFormData(form.getFieldsValue(true), calenderYear, FYCount, CYACount, assessmentYearWithDateAndMonth);
                await sendFormData(formattedData, creditRequestId, parentAssessmentFrom);
            }
            setReloadComponentCnt(Math.random());
        } catch (error) {
            console.error('Error while sending form data:', error);
        }
    };

    // Parse financialSpreading data if available
    const parsedFinancialSpreading = financialSpreading ? {
        ...financialSpreading,
        shareCapital: JSON.parse(financialSpreading.shareCapital),
        reserveAndSurplus: JSON.parse(financialSpreading.reserveAndSurplus),
        totalEquity: JSON.parse(financialSpreading.totalEquity),
        longTermBorrowings: JSON.parse(financialSpreading.longTermBorrowings),
        netDeferredTaxLiabilities: JSON.parse(financialSpreading.netDeferredTaxLiabilities),
        otherLongTermLiabilities: JSON.parse(financialSpreading.otherLongTermLiabilities),
        totalNonCurrentLiabilities: JSON.parse(financialSpreading.totalNonCurrentLiabilities),
        shortTermBorrowings: JSON.parse(financialSpreading.shortTermBorrowings),
        tradePayables: JSON.parse(financialSpreading.tradePayables),
        otherCurrentLiabilities: JSON.parse(financialSpreading.otherCurrentLiabilities),
        totalCurrentLiabilities: JSON.parse(financialSpreading.totalCurrentLiabilities),
        totalNetFixedAssets: JSON.parse(financialSpreading.totalNetFixedAssets),
        inventory: JSON.parse(financialSpreading.inventory),
        tradeReceivables: JSON.parse(financialSpreading.tradeReceivables),
        cashAndBankBalances: JSON.parse(financialSpreading.cashAndBankBalances),
        shortTermLoanAndAdvances: JSON.parse(financialSpreading.shortTermLoanAndAdvances),
        otherCurrentAssets: JSON.parse(financialSpreading.otherCurrentAssets),
        totalCurrentAssets: JSON.parse(financialSpreading.totalCurrentAssets),
        netRevenue: JSON.parse(financialSpreading.netRevenue),
        otherIncome: JSON.parse(financialSpreading.otherIncome),
        operatingProfits: JSON.parse(financialSpreading.operatingProfits),
        netProfits: JSON.parse(financialSpreading.netProfits),


        operatingCosts: JSON.parse(financialSpreading.operatingCosts),
        costOfMaterialsConsumes: JSON.parse(financialSpreading.costOfMaterialsConsumes),
        employeeBenefitExpenses: JSON.parse(financialSpreading.employeeBenefitExpenses),
        financeCosts: JSON.parse(financialSpreading.financeCosts),
        depreciationAndAmortizationExpenses: JSON.parse(financialSpreading.depreciationAndAmortizationExpenses)
        // Add parsing for other fields as needed
    } : undefined;

    // Helper function to generate initial values
    const generateInitialValues = (FYCount: number, financialSpreading?: any) => ({
        shareCapital: financialSpreading ? financialSpreading.shareCapital : Array.from({ length: FYCount }, () => 0),
        reserveAndSurplus: financialSpreading ? financialSpreading.reserveAndSurplus : Array.from({ length: FYCount }, () => 0),
        totalEquity: financialSpreading ? financialSpreading.totalEquity : Array.from({ length: FYCount }, () => 0),
        longTermBorrowings: financialSpreading ? financialSpreading.longTermBorrowings : Array.from({ length: FYCount }, () => 0),
        netDeferredTaxLiabilities: financialSpreading ? financialSpreading.netDeferredTaxLiabilities : Array.from({ length: FYCount }, () => 0),
        otherLongTermLiabilities: financialSpreading ? financialSpreading.otherLongTermLiabilities : Array.from({ length: FYCount }, () => 0),
        totalNonCurrentLiabilities: financialSpreading ? financialSpreading.totalNonCurrentLiabilities : Array.from({ length: FYCount }, () => 0),
        shortTermBorrowings: financialSpreading ? financialSpreading.shortTermBorrowings : Array.from({ length: FYCount }, () => 0),
        tradePayables: financialSpreading ? financialSpreading.tradePayables : Array.from({ length: FYCount }, () => 0),
        otherCurrentLiabilities: financialSpreading ? financialSpreading.otherCurrentLiabilities : Array.from({ length: FYCount }, () => 0),
        totalCurrentLiabilities: financialSpreading ? financialSpreading.totalCurrentLiabilities : Array.from({ length: FYCount }, () => 0),
        totalNetFixedAssets: financialSpreading ? financialSpreading.totalNetFixedAssets : Array.from({ length: FYCount }, () => 0),
        inventory: financialSpreading ? financialSpreading.inventory : Array.from({ length: FYCount }, () => 0),
        tradeReceivables: financialSpreading ? financialSpreading.tradeReceivables : Array.from({ length: FYCount }, () => 0),
        cashAndBankBalances: financialSpreading ? financialSpreading.cashAndBankBalances : Array.from({ length: FYCount }, () => 0),
        shortTermLoanAndAdvances: financialSpreading ? financialSpreading.shortTermLoanAndAdvances : Array.from({ length: FYCount }, () => 0),
        otherCurrentAssets: financialSpreading ? financialSpreading.otherCurrentAssets : Array.from({ length: FYCount }, () => 0),
        totalCurrentAssets: financialSpreading ? financialSpreading.totalCurrentAssets : Array.from({ length: FYCount }, () => 0),
        netRevenue: financialSpreading ? financialSpreading.netRevenue : Array.from({ length: FYCount }, () => 0),
        otherIncome: financialSpreading ? financialSpreading.otherIncome : Array.from({ length: FYCount }, () => 0),
        operatingProfits: financialSpreading ? financialSpreading.operatingProfits : Array.from({ length: FYCount }, () => 0),
        netProfits: financialSpreading ? financialSpreading.netProfits : Array.from({ length: FYCount }, () => 0),


        operatingCosts: financialSpreading ? financialSpreading.operatingCosts : Array.from({ length: FYCount }, () => 0),
        costOfMaterialsConsumes: financialSpreading ? financialSpreading.costOfMaterialsConsumes : Array.from({ length: FYCount }, () => 0),
        employeeBenefitExpenses: financialSpreading ? financialSpreading.employeeBenefitExpenses : Array.from({ length: FYCount }, () => 0),
        financeCosts: financialSpreading ? financialSpreading.financeCosts : Array.from({ length: FYCount }, () => 0),
        depreciationAndAmortizationExpenses: financialSpreading ? financialSpreading.depreciationAndAmortizationExpenses : Array.from({ length: FYCount }, () => 0)
        // Add defaults for other fields
    });

    const generateInititalValueForFYChange = (label: string)=>{
        const formValue = form.getFieldValue(label);
        const formValueList = Array.isArray(formValue) ? formValue : [];
        if(formValueList.length < CYACount + FYCount){
            const resList = [...formValueList, ...Array.from({ length: CYACount + FYCount-formValueList.length }, () => 0)];
            return resList;
        }
        else{
            const res = formValueList.filter((val:number, ind:number)=>ind<FYCount+CYACount );
            return res;
        }
    }

    const generateInititalValueForCYAChange = (label: string)=>{
        const formValue = form.getFieldValue(label);
        const formValueList = Array.isArray(formValue) ? formValue : [];
        if(formValueList.length < CYACount + FYCount){
            const resList = [...Array.from({ length: CYACount + FYCount-formValueList.length }, () => 0), ...formValueList];
            return resList;
        }
        else{
            const res = formValueList.filter((val:number, ind:number)=>ind>=formValueList.length - FYCount - CYACount);
            return res;
        }
    }

    
    // Generate initial values function
    const generateInitialValuesForFYChange = (FYCount: number) => ({
        shareCapital: generateInititalValueForFYChange('shareCapital'),
        reserveAndSurplus: generateInititalValueForFYChange('reserveAndSurplus'),
        totalEquity: generateInititalValueForFYChange('totalEquity'),
        longTermBorrowings: generateInititalValueForFYChange('longTermBorrowings'),
        netDeferredTaxLiabilities: generateInititalValueForFYChange('netDeferredTaxLiabilities'),
        otherLongTermLiabilities: generateInititalValueForFYChange('otherLongTermLiabilities'),
        totalNonCurrentLiabilities: generateInititalValueForFYChange('totalNonCurrentLiabilities'),
        shortTermBorrowings: generateInititalValueForFYChange('shortTermBorrowings'),
        tradePayables: generateInititalValueForFYChange('tradePayables'),
        otherCurrentLiabilities: generateInititalValueForFYChange('otherCurrentLiabilities'),
        totalCurrentLiabilities: generateInititalValueForFYChange('totalCurrentLiabilities'),
        totalNetFixedAssets: generateInititalValueForFYChange('totalNetFixedAssets'),
        inventory: generateInititalValueForFYChange('inventory'),
        tradeReceivables: generateInititalValueForFYChange('tradeReceivables'),
        cashAndBankBalances: generateInititalValueForFYChange('cashAndBankBalances'),
        shortTermLoanAndAdvances: generateInititalValueForFYChange('shortTermLoanAndAdvances'),
        otherCurrentAssets: generateInititalValueForFYChange('otherCurrentAssets'),
        totalCurrentAssets: generateInititalValueForFYChange('totalCurrentAssets'),
        netRevenue: generateInititalValueForFYChange('netRevenue'),
        otherIncome: generateInititalValueForFYChange('otherIncome'),
        operatingProfits: generateInititalValueForFYChange('operatingProfits'),
        netProfits: generateInititalValueForFYChange('netProfits'),

        // Add defaults for other fields
        operatingCosts: generateInititalValueForFYChange('operatingCosts'),
        costOfMaterialsConsumes: generateInititalValueForFYChange('costOfMaterialsConsumes'),
        employeeBenefitExpenses: generateInititalValueForFYChange('employeeBenefitExpenses'),
        financeCosts: generateInititalValueForFYChange('financeCosts'),
        depreciationAndAmortizationExpenses: generateInititalValueForFYChange('depreciationAndAmortizationExpenses'),
    });

    // Generate initial values function
    const generateInitialValuesForCYAChange = (FYCount: number) => ({
        shareCapital: generateInititalValueForCYAChange('shareCapital'),
        reserveAndSurplus: generateInititalValueForCYAChange('reserveAndSurplus'),
        totalEquity: generateInititalValueForCYAChange('totalEquity'),
        longTermBorrowings: generateInititalValueForCYAChange('longTermBorrowings'),
        netDeferredTaxLiabilities: generateInititalValueForCYAChange('netDeferredTaxLiabilities'),
        otherLongTermLiabilities: generateInititalValueForCYAChange('otherLongTermLiabilities'),
        totalNonCurrentLiabilities: generateInititalValueForCYAChange('totalNonCurrentLiabilities'),
        shortTermBorrowings: generateInititalValueForCYAChange('shortTermBorrowings'),
        tradePayables: generateInititalValueForCYAChange('tradePayables'),
        otherCurrentLiabilities: generateInititalValueForCYAChange('otherCurrentLiabilities'),
        totalCurrentLiabilities: generateInititalValueForCYAChange('totalCurrentLiabilities'),
        totalNetFixedAssets: generateInititalValueForCYAChange('totalNetFixedAssets'),
        inventory: generateInititalValueForCYAChange('inventory'),
        tradeReceivables: generateInititalValueForCYAChange('tradeReceivables'),
        cashAndBankBalances: generateInititalValueForCYAChange('cashAndBankBalances'),
        shortTermLoanAndAdvances: generateInititalValueForCYAChange('shortTermLoanAndAdvances'),
        otherCurrentAssets: generateInititalValueForCYAChange('otherCurrentAssets'),
        totalCurrentAssets: generateInititalValueForCYAChange('totalCurrentAssets'),
        netRevenue: generateInititalValueForCYAChange('netRevenue'),
        otherIncome: generateInititalValueForCYAChange('otherIncome'),
        operatingProfits: generateInititalValueForCYAChange('operatingProfits'),
        netProfits: generateInititalValueForCYAChange('netProfits'),

        // Add defaults for other fields
        operatingCosts: generateInititalValueForCYAChange('operatingCosts'),
        costOfMaterialsConsumes: generateInititalValueForCYAChange('costOfMaterialsConsumes'),
        employeeBenefitExpenses: generateInititalValueForCYAChange('employeeBenefitExpenses'),
        financeCosts: generateInititalValueForCYAChange('financeCosts'),
        depreciationAndAmortizationExpenses: generateInititalValueForCYAChange('depreciationAndAmortizationExpenses'),
    });
    
    console.log(`assessmentYearWithDateAndMonth=%o`,assessmentYearWithDateAndMonth)
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: '#FFF', marginTop: '20px', borderRadius: '8px' }}>
                <div onClick={() => setCollapseFinancialSpreading(oldState => !oldState)} style={{padding: '20px'}}>
                    <span>
                        {collapseFinancialSpreading ? <ArrowRight /> : <ArrowDown />}
                    </span>
                    <span style={{ color: '#242424', fontSize: '14px', fontWeight: '700', marginLeft: '10px' }}>
                        Financial Spreading
                    </span>
                </div>
                {!collapseFinancialSpreading && (
                    <>
                        <Form
                            form={form}
                            style={{ marginTop:'10px', borderRight: '1px #E8E8E8 solid', borderLeft: '1px #E8E8E8 solid', padding: '0px 7px 0px 7px' }}
                            onFinish={onFinish}
                        >
                            <Row gutter={16} style={{ backgroundColor: '#CEE6FA', padding: '15px 10px 15px 16px' }}>
                                <Col span={labelColSpan}></Col>
                                {
                                    <>
                                        { 
                                            assessmentYearWithDateAndMonth.map((data:string) => <Col span={colSpan} style={{ fontWeight: '600' }}>
                                                    {data}
                                                </Col>)
                                        }
                                    </>
                                }
                            </Row>
                            <EquityComponent FYCount={FYCount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive} CYACount={CYACount} setCYACount={setCYACount} CYADateRanges={CYADateRanges} setCYADateRanges={setCYADateRanges}/>
                            <NonCurrentLiabilitiesComponent FYCount={FYCount} CYACount={CYACount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <TotalCurrentLiabilitiesComponent FYCount={FYCount} CYACount={CYACount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px', borderBottom: '1px #E8E8E8 solid' }}>
                                <Col span={labelColSpan}>
                                    <Typography style={{ marginLeft: '20px', fontWeight: '600' }}>Total Net Fixed Assets</Typography>
                                </Col>
                                {Array.from({ length: CYACount + FYCount }, (_, ind) => (
                                    <Col span={colSpan} key={ind}>
                                        {viewModeActive ? (
                                            <Typography style={{ fontWeight: '600', paddingLeft: '10px' }}>
                                                <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['totalNetFixedAssets', ind]))}`}</span>
                                            </Typography>
                                        ) : (
                                            <Form.Item
                                                name={['totalNetFixedAssets', ind]}
                                                rules={[{ required: true, message: 'Please enter Total Net Fixed Assets value' }]}
                                            >
                                                <Input style={{ fontWeight: '600' }} type="number" />
                                            </Form.Item>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                            <TotalCurrentAssetsComponent FYCount={FYCount} CYACount={CYACount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                            {/* <OperationFinancialSpreadingComponent FYCount={FYCount} CYACount={CYACount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} updateTotalCnt={updateTotalCnt}
                                viewModeActive={viewModeActive}/>
                             */}
                            <OtherFinancialSpreadingComponent FYCount={FYCount} CYACount={CYACount} form={form} colSpan={colSpan} labelColSpan={labelColSpan} 
                                viewModeActive={viewModeActive} updateTotalCnt={updateTotalCnt}/>
                        </Form>
                        
                    </>
                )}

            </div>
        </>
    );
};

export default FinancialSpreading;
