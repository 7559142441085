import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Form, Typography } from 'antd';
import { ReactComponent as DownMark } from '../../../../utils/SVGs/DownMark.svg';
import { ReactComponent as RightMark } from '../../../../utils/SVGs/RightMark.svg';
import { formatIndianRupee } from '../formUtils';

interface TotalCurrentLiabilitiesProps {
    FYCount: number;
    CYACount : number;
    form: any; // Form instance from Ant Design Form
    colSpan: number;
    labelColSpan: number;
    updateTotalCnt: number;
    viewModeActive: boolean;
}

const TotalCurrentLiabilitiesComponent: React.FC<TotalCurrentLiabilitiesProps> = ({ FYCount, CYACount, form, colSpan, updateTotalCnt, viewModeActive, labelColSpan }) => {
    const [totalCurrentLiabilities, setTotalCurrentLiabilities] = useState<number[]>(Array(FYCount).fill(0));
    const [collapseCurrentLiabilitiesFields, setCollapseCurrentLiabilitiesFields] = useState<boolean>(false);
    const INR_SYMBOL: string = '\u20B9';
    
    useEffect(() => {
        const values = form.getFieldsValue(true);
        if (values.totalCurrentLiabilities) {
            setTotalCurrentLiabilities(values.totalCurrentLiabilities);
        }
    }, [updateTotalCnt]);

    const handleFieldChange = () => {
        const values = form.getFieldsValue();
        const updatedTotalCurrentLiabilities = values.shortTermBorrowings.map(
            (item: number, i: number) =>
                Number(item) +
                Number(values.tradePayables[i]) +
                Number(values.otherCurrentLiabilities[i])
        );
        form.setFieldsValue({ totalCurrentLiabilities: updatedTotalCurrentLiabilities });
        setTotalCurrentLiabilities(updatedTotalCurrentLiabilities);
    };

    return (
        <>
            <Row
                gutter={16}
                style={{ marginTop: '20px', paddingTop: '10px', paddingBottom: '20px', borderBottom: '1px #E8E8E8 solid' }}
            >
                <Col span={labelColSpan}>
                    <span onClick={() => setCollapseCurrentLiabilitiesFields((oldState) => !oldState)}>
                        {collapseCurrentLiabilitiesFields ? <RightMark /> : <DownMark />}
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '10px' }}>Total Current Liabilities</span>
                </Col>
                {totalCurrentLiabilities.map((value, ind) => (
                    <Col span={colSpan} key={ind}>
                        <Typography style={{ fontSize: 14, fontWeight: '600', paddingLeft: '10px' }}>
                            <span>{`${INR_SYMBOL} ${formatIndianRupee(parseFloat(value.toFixed(2)))}`}</span>
                        </Typography>
                    </Col>
                ))}
            </Row>
            {!collapseCurrentLiabilitiesFields && (
                <>
                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Short Term Borrowings</p></Col>
                        {Array.from({ length: CYACount + FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['shortTermBorrowings', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['shortTermBorrowings', ind]}
                                        rules={[{ required: true, message: 'Please enter Short Term Borrowings value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Trade Payables</p></Col>
                        {Array.from({ length: CYACount + FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['tradePayables', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['tradePayables', ind]}
                                        rules={[{ required: true, message: 'Please enter Trade Payables value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={16} style={{ paddingTop: '20px', paddingBottom: viewModeActive?'20px':'0px',  borderBottom: '1px #E8E8E8 solid', backgroundColor: '#F6F6F6' }}>
                        <Col span={labelColSpan}><p style={{ marginLeft: '20px' }}>Other Current Liabilities</p></Col>
                        {Array.from({ length: CYACount + FYCount }, (_, ind) => (
                            <Col span={colSpan} key={ind}>
                                {viewModeActive ? (
                                    <Typography style={{ paddingLeft: '10px' }}>
                                        <span>{`${INR_SYMBOL} ${formatIndianRupee(form.getFieldValue(['otherCurrentLiabilities', ind]))}`}</span>
                                    </Typography>
                                ) : (
                                    <Form.Item
                                        name={['otherCurrentLiabilities', ind]}
                                        rules={[{ required: true, message: 'Please enter Other Current Liabilities value' }]}
                                    >
                                        <Input type="number" onChange={handleFieldChange} />
                                    </Form.Item>
                                )}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
};

export default TotalCurrentLiabilitiesComponent;
