import React, { useState } from "react";
import { Button, Card, Empty, Modal, Space, Typography } from "antd";
import _ from "lodash";
import { ReactComponent as EyeIcon } from '../../SVGs/eye.svg';
import { DownloadOutlined } from "@ant-design/icons";
import { handleFileDownload } from "../../../../utils/handler/apiHandler";

interface FilePreviewModalProps {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    data: any
}

const previewFileType = ["pdf", "jpg", "jpeg"];

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({ showModal, setShowModal, data }) => {
    const [filePreviewLink, setFilePreviewLink] = useState("");
    return <Modal
    title={<div style={{fontSize: 14}}>View proof files</div>}
    centered
    open={showModal}
    onCancel={() => {setShowModal(false); setFilePreviewLink("");}}
    footer={null}
    className="preview-modal"
    width={ _.isEmpty(filePreviewLink) ? "60%" : "90%" }
>
    <div style={{display: "flex"}}>
    {_.isEmpty(data) 
    ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    : <div style={{marginRight: 10, width: _.isEmpty(filePreviewLink) ? "100%" : "40%"}}>
        {data?.map((file: any) => {
            return <div style={{display: "flex", justifyContent: "space-between", backgroundColor: "white", marginBottom: 8, padding: 10, borderRadius: 4, boxSizing: "border-box", width: "100%" }}>
                <span style={{fontWeight: 500}}>{file?.name}</span>
                <Space>
                    {previewFileType.includes(file?.url.split(".").pop() || "") && <span style={{color: "#2c66e3", cursor: "pointer"}} onClick={() => setFilePreviewLink(file?.url)}><EyeIcon /></span>}
                    <Button type="link" onClick={()=>handleFileDownload(file?.url,file?.name,file?.url.split(".").pop())} icon={<DownloadOutlined />} />
                </Space>
            </div>
        })}
    </div>
    }
    {!_.isEmpty(filePreviewLink) && <Card style={{ width: _.isEmpty(filePreviewLink) ? "100%" : "60%"}}>
        {previewFileType.includes(filePreviewLink.split(".").pop() || "") ? 
        <>
            <div style={{fontWeight:'700', fontSize:'14px'}}>{filePreviewLink.split('/').pop()}</div>
            <iframe
            src={filePreviewLink}
            style={{ height: "80vh", width: "100%", border: "none" }}
            />
        </>
        : <Empty 
            style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",  height: "60vh"}}
            description={
            <Typography.Text>
              Preview not available
            </Typography.Text>
          }
        />}
    </Card>}
    </div>
</Modal>
}

export default FilePreviewModal;