import { Button, Col, Row, Spin, Typography } from "antd";
import { ReactComponent as TickIcon } from '../../Dashboard/SVGs/tick_icon.svg';
import { ReactComponent as EditIcon } from '../../Dashboard/SVGs/edit_icon_vehicle.svg';
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface CreateTicketModalProps {
    loader: boolean;
    details: any;
    // setShowRejectModal: (showRejectModal: boolean) => void;
    // setShowETAModal: (showETAModal: boolean) => void;
    // setStatusId: (statusId: any) => void;
    // setTitle: (title: any) => void;
    // setSubTitle: (subTitle: any) => void;
    acceptLoader: boolean;
}

const CreateTicketModal : React.FC<CreateTicketModalProps> = ({loader, acceptLoader, details}) => {
        return(
            <div style={{display: 'flex', flexDirection: 'column', alignItems : 'start'}}>
                <h4>Vehicle Details</h4>
                <p>Update Details to Create a Ticket</p>
                <div style={{display : 'flex', flexWrap : 'wrap', width : '100%', marginTop : "15px", marginBottom : "20px"}}>
                    <div className="requirement-card-info" style={{marginLeft : '-37px'}}>
                        <Typography className="description-label">Odometer Reading</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                            <span style={{display: loader?"none":""}}>{
                                loader ? "" :  <span>{details?.currentOdometer} <EditIcon/></span> 
                            }</span>
                        </Typography>
                    </div>
                    <div className="requirement-card-info">
                        <Typography className="description-label">Service Number</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                            <span style={{display: loader?"none":""}}>{
                                loader ? "" :  <span>{details?.serviceNumber} <EditIcon/></span> 
                            }</span>
                        </Typography>
                    </div>
                    <div className="requirement-card-info">
                        <Typography className="description-label">Last Service Date</Typography>
                        <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                            <span style={{display: loader?"none":""}}>{
                                loader ? "" : details?.lastServiceDate 
                            }</span>
                        </Typography>
                        
                    </div>
                </div>
                <div style={{display : 'flex', justifyContent : 'flex-end', width : '100%'}}>
                    <Button
                        type="primary"
                        onClick={() => {}}
                        icon={acceptLoader ? <Spin indicator={antIcon} /> : <TickIcon style={{marginLeft: 10}}/>}
                        style={{marginLeft: 10}}
                        className="button-override"
                        > Proceed
                    </Button>
                </div>

            </div>

        );
}

export default CreateTicketModal;