import { Table, Spin, Tooltip, Select, Tag, Typography, Row, Button, Col, Input, Space, Checkbox, Tabs } from "antd";
import "../../Client/table.css"
import AllClientQuotes from "./AllClientQuotes";

export default function ClientQuoyteListing() {
    return (
        <div className="parent-container">
            <div className="container-layout">
                <Row justify="space-between" align="top" style={{ marginBottom: 20 }}>
                    <Col>
                        <Typography style={{ fontSize: 20, fontWeight: 700 }}>Client Based Quotes</Typography>
                    </Col>
                </Row>
                <Tabs defaultActiveKey="1" items={[{
                    key: '1',
                    label: 'All Clients Quotes',
                    children: <AllClientQuotes showConfirmed={false} />
                },
                {
                    key: '2',
                    label: 'Order Confirmed',
                    children: <AllClientQuotes showConfirmed={true} />,
                },]} />
            </div>
        </div>
    );
}
