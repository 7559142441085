import { Button, Card, Modal, Spin, Table, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { getRequest } from "../../../../utils/handler/apiHandler";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import FilePreviewModal from "./FilePreviewModal";
import ReadMoreText from "../../Client/ReadMoreText";

interface PropType {
    visible: boolean,
    setVisible: (t: boolean) => void,
    quoteId: number,
    clientData: any
}

const tableHeight = 600

export default function PaymentHistoryModal({ visible, setVisible, quoteId, clientData }: PropType, ) {
    const tableRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [trancheRemarks, setTrancheRemarks] = useState<string>("");
    const [data, setData] = useState<any[]>([]);
    const [additionalCharges, setAdditionalCharges] = useState([])
    const [showChargesModal, setShowChargesModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [proofFiles, setProofFiles] = useState([])

    useEffect(() => {
        if (visible && clientData?.quoteStatus?.id > 14 ) {
            fetchAllRequestedQuoteTableData();
        }
    }, [visible, clientData]);

    const fetchAllRequestedQuoteTableData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/payment-confirmation/paymentHistory?clientQuoteId=${quoteId}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                console.log("res data", res.data)
                setData(res.data.paymentConfirmationDetailTranches);

            } else {
                console.error('Error fetching Requested Table List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Requested data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleShowCharges = (value: any, record: any) => {
        setShowChargesModal(true)
        setTrancheRemarks(record?.remarks);
        setAdditionalCharges(value)
    }

    const handleCloseCharges = () => {
        setShowChargesModal(false)
        setAdditionalCharges([])
    }

    const getAdditionalChargesColumns = (): ColumnsType<any> => [
        {
            title: "Additional Charge",
            dataIndex: "additionalChargesName",
            key: "additionalChargesName",
        },
        {
            title: "Additional Charge Value",
            dataIndex: 'additionalChargesValue',
            key: 'additionalChargesValue',
            render: (amount: number) => amount && `₹ ${amount}`,
        },
        
    ];


    const getColumns = (): ColumnsType<any> => [
        {
            title: "No",
            dataIndex: "trancheId",
            key: "trancheId",
            width: 50,
            render: (_: any, record: any, index: number) => index + 1,
        },
        {
            title: <>Security<br />Deposit</>,
            dataIndex: 'securityDepositReceived',
            key: 'securityDepositReceived',
            render: (amount: number) => amount && `₹ ${amount}`,
            width: 150
        },
        {
            title: <>Processing<br />Fees</>,
            dataIndex: "processingFeeReceived",
            key: "processingFeeReceived",
            render: (amount: number) => amount && `₹ ${amount}`,
            width: 150
        },
        {
            title: <>Down<br />Payment</>,
            dataIndex: "downPayment",
            key: "downPayment",
            render: (amount: number) => amount && `₹ ${amount}`,
            width: 150
        },
        {
            title: "UTR no.",
            dataIndex: "utrNo",
            key: "utrNo",
            // sorter: { multiple: 2 },
            width: 150
        },
        {
            title: <>Transaction<br />Date</>,
            dataIndex: "transactionDate",
            key: "transactionDate",
            render: (date: string) => date ? moment(date).format("DD MMM YY") : "",
            // sorter: { multiple: 2 },
            width: 100
        },
        {
            title: <>Additional<br />Charges</>,
            dataIndex: "additionalCharges",
            key: "additionalCharges",
            render: (additionalCharges: any, record: any) => additionalCharges?.length > 0 ?
                <Typography.Text onClick={() => handleShowCharges(additionalCharges, record)} style={{ color: "rgb(22, 119, 255)", cursor: "pointer" }}>
                    {additionalCharges.map((charge: any) => charge.additionalChargesValue).reduce((acc: any, value: any) => acc + value, 0)} <DownOutlined />
                </Typography.Text>
                : "",
            // sorter: { multiple: 2 },
            width: 100
        },
        {
            title: <>No. of<br />Vehicle</>,
            dataIndex: "numberOfVehiclePaymentDoneFor",
            key: "numberOfVehiclePaymentDoneFor",
            width: 100
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Button disabled={record?.clientFileDetails?.length == 0} onClick={()=>{setProofFiles(record?.clientFileDetails); setShowModal(true)}} style={{padding: 0}} type="link">View Proof</Button>
            ),
        },
    ];
    return (
        <>
            <Modal width={1000} onCancel={() => setVisible(false)} title="Payment History" open={visible} footer={null}>
                <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
                    <div>
                        <Spin spinning={loading} size="large">
                            <Table
                                className="lev-table"
                                columns={getColumns()}
                                dataSource={data || []}
                                pagination={false}
                                // scroll={{ x: 1700, y: 0.80 * tableHeight }}
                                size="middle"
                            // components={{
                            //     header: {
                            //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                            //     },
                            //   }}
                            />
                        </Spin>
                    </div>
                </div>
                <Modal width={1000} onCancel={() => { setShowChargesModal(false); setAdditionalCharges([]) }} title="Additional Charges" open={showChargesModal} footer={null}>
                    <div style={{ height: '100%' }}>
                        <div>
                                <Table
                                    className="lev-table"
                                    columns={getAdditionalChargesColumns()}
                                    dataSource={additionalCharges || []}
                                    pagination={false}
                                    // scroll={{ x: 1700, y: 0.80 * tableHeight }}
                                    size="middle"
                                // components={{
                                //     header: {
                                //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                                //     },
                                //   }}
                                />
                                    
                                <Typography style={{fontWeight: 700, margin: "20px 0 10px 0"}}>Remarks</Typography>
                                <Card bordered={false}>
                                    <ReadMoreText changed={false} text={trancheRemarks || "NA"} />
                                </Card>
                        </div>
                    </div>
                </Modal>
                {showModal && <FilePreviewModal data={proofFiles} showModal={showModal} setShowModal={setShowModal} />}
            </Modal>
        </>

    )
}