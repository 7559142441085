import { Button, Modal, Spin, Typography } from "antd";
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;



interface ModalProps {
    title : string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    CYACount : number;
    setCYACount: (count: number) => void;
    CYADateRanges : any[];
    setCYADateRanges : (newDateRange: any) => void;
    dates : any
    setDates : (date : any) => void;
}

const CYADatePickerModal: React.FC<ModalProps> = ({title, showModal, setShowModal, CYACount, setCYACount, CYADateRanges, setCYADateRanges, dates, setDates}) => {

    const handleOk = () => {
        setCYACount(CYACount+1);
        const obj = {
            startDate : dayjs(dates[0]).format("DD MMM YYYY"),
            endDate : dayjs(dates[1]).format("DD MMM YYYY")
        }
        setCYADateRanges([obj, ...CYADateRanges]);
        setDates(null);
        setShowModal(false);
    }

    return (
        <div>
            <Modal
                title={<Typography style={{background: "#F6FAFF", fontSize: 14, fontWeight: 500, color: "#404040"}}>{title}</Typography>}
                centered
                open={showModal}
                onCancel={() => {setShowModal(false); setDates(null);}}
                afterClose={() => {}}
                className="preview-modal"
                width={500}
                footer={<>

                    </>}
            >
                <div style={{color : 'grey', backgroundColor: "white", fontWeight : 500, fontSize : 20, padding: '40px 50px', 
                    border: '1px solid #D3D3D3', borderRadius: '7px'}}>
                        <div style={{display:'flex', justifyContent:'space-around'}}>
                            <RangePicker
                            value={dates}
                            onChange={(dates) => setDates(dates)}
                            style={{marginRight : 20}}
                            />  
                        </div>   
                        <div style={{marginTop:'1rem', marginLeft:'2.5rem'}}>                    
                            <Button
                                ghost
                                danger
                                onClick={_ => {setShowModal(false); setDates(null);}}
                                className="button-override danger"
                                style={{height: '2.2rem', width:'5rem'}}
                                icon={<CrossIcon />}
                                
                                >
                                Cancel
                            </Button>
                            <Button icon={<TickIcon color={'#FFFFFF'} />}
                                disabled = {!dates}
                                style={{ color: '#FFFFFF', backgroundColor: dates ? '#1777FF' : '#D3D3D3', height: '2.2rem', width:'5rem', marginLeft: '1.6rem', fontWeight: '400', fontSize: '14px' }}
                                key="confirm" type="primary" onClick={() => { handleOk()}}>
                                Ok
                            </Button>
                        </div>
                        
                        
                </div>

            </Modal>
        </div>
    );
}

export default CYADatePickerModal;