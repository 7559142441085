import { Card, Modal, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { ReactComponent as EyeIcon } from '../../SVGs/eye.svg';
import { Empty } from 'antd';
import _ from "lodash";
import VersionHistory from "./VersionHistory";
import FilePreviewModal from "./FilePreviewModal";
import ReadMoreText from "../../Client/ReadMoreText";

interface AdditionalQuoteDetailsProps {
    clientData: any;
    additionalDetailsLog: any;
}
const { Title } = Typography;
const AdditionalQuoteDetails: React.FC<AdditionalQuoteDetailsProps> = ({ additionalDetailsLog, clientData }) => {
    const [showModal, setShowModal] = useState(false);
    const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);

    return <React.Fragment>
        <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 30}}>
            <Title style={{margin: 0, fontWeight: 700}} level={5}>Additional Quote Details</Title>
            <div onClick={() => setShowVersionHistory(true)} style={{display: "flex", alignItems: "center", color: "#2c66e3", fontWeight: 500, fontSize: 12, cursor: "pointer"}}><EyeIcon style={{marginRight: 5}}/> Show Version History</div>
        </div>

        <Card bordered={false} style={{marginTop: 20}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Ownership Type</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.ownershipType || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">No. of Vehicle</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.numberOfVehicles || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Color</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.color || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Battery Capacity</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.batteryCapacity ? clientData?.additionalQuoteDetails?.batteryCapacity + "kWh" : "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">VTS</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.vts || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">VTS Remark</Typography>
                <ReadMoreText changed={false} text={clientData?.additionalQuoteDetails?.vtsRemarks || "NA"} />
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">User City</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.userCity || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Registration City</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.registrationCity || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Registration State</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.registrationState || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Payment Mode</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.paymentMode || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Billing Frequency</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.billingFrequency || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Insurance</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.insurance || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Insurance Remark</Typography>
                <ReadMoreText changed={false} text={clientData?.additionalQuoteDetails?.insuranceRemarks || "NA" } />
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Payment Due Date</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.paymentDueDate || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Security Deposit Type</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.securityDepositType || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Security Deposit Value</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.securityDepositValue || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Processing Fees</Typography>
                <Typography style={{fontSize: 14, minHeight: 15}}>{ clientData?.additionalQuoteDetails?.processingFees || "NA" }</Typography>
                </div>

                <div className="requirement-card-info" style={{width: "25%"}}>
                <Typography className="description-label">Remarks <span onClick={() => setShowModal(true)} style={{color: "#2c66e3", marginLeft: 8, cursor: "pointer" }}><EyeIcon style={{transform: "scale(0.9)"}} /> View File</span></Typography>
                <ReadMoreText changed={false} text={clientData?.additionalQuoteDetails?.remarks || "NA"} />

                </div>
            </div>
        </Card>

        <FilePreviewModal data={clientData?.additionalQuoteDetails?.remarkFiles} showModal={showModal} setShowModal={setShowModal} />
        <VersionHistory data={additionalDetailsLog} showVersionHistory={showVersionHistory} setShowVersionHistory={setShowVersionHistory} />
    </React.Fragment>
}

export default AdditionalQuoteDetails;