// RatioAnalysis.tsx

import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { ReactComponent as ArrowDown } from '../../../../utils/SVGs/ArrowDown.svg';
import { ReactComponent as ArrowRight } from '../../../../utils/SVGs/ArrowRight.svg';
import { formatFormData, formatRatioAnalysisFormData, formatUpdateRatioAnalysisFormData } from '../formUtils';
import { sendFormData, sendRatioAnalysisFormData, sendUpdateRatioAnalysisFormData } from '../apiCalls';
import OtherRatioAnalysisComponent from './OtherRatioAnalysisComponent';

interface RatioAnalysisProps {
    FYCount: number;
    CYACount : number;
    calenderYear: boolean;
    creditRequestId: number;
    assessmentFormFlag: number;
    ratioAnalysis: any;
    creditRequestData: any;
    submitForm: boolean;
    setSubmitForm: (flag: boolean)=>void;
    setReloadComponentCnt: (value:number) => void;
    parentAssessmentFrom: boolean;
    assessmentYearWithDateAndMonth : string[];
}

const RatioAnalysis: React.FC<RatioAnalysisProps> = ({ FYCount, CYACount, calenderYear, creditRequestId, assessmentFormFlag ,ratioAnalysis,
        creditRequestData, submitForm, setSubmitForm, setReloadComponentCnt, parentAssessmentFrom, assessmentYearWithDateAndMonth }) => {
    const [collapseRatioAnalysis, setCollapseRatioAnalysis] = useState<boolean>(true);
    const [colSpan, setColSpan] = useState<number> (0);
    const [viewModeActive, setViewModeActive] = useState<boolean>(false);
    const [rerenderCnt, setRerenderCnt] = useState<number>(0);
    const [labelColSpan, setLabelColSpan] =  useState<number>(5);
    const [form] = Form.useForm();
    const FORM_ID: number = 2;
    
    const netRevenueJSON = creditRequestData?.financialSpreading?.netRevenue;
    const netProfitsJSON = creditRequestData?.financialSpreading?.netProfits;
    const totalNetFixedAssetsJSON = creditRequestData?.financialSpreading?.totalNetFixedAssets;

    const revenueGrowthList = getGrowthPercentage(netRevenueJSON ? JSON.parse(netRevenueJSON) : []);
    const netProfitGrowthList = getGrowthPercentage(netProfitsJSON ? JSON.parse(netProfitsJSON) : []);
    const salesAndNetFixedAssetsGrowthList = getGrowthRatio(netRevenueJSON ? JSON.parse(netRevenueJSON) : [],
        totalNetFixedAssetsJSON ? JSON.parse(totalNetFixedAssetsJSON) : []);
    function getGrowthPercentage (lst:number[]){
    if(lst){
            return lst.map((revenue:number,ind:number) => {
                if(ind < lst.length-1){
                 return (revenue - lst[ind+1])*100 / lst[ind+1];
                }
                else return 0;
        })
        }
        else return [];
    }
    function getGrowthRatio (lst1:number[], lst2:number[]){
        if(lst1 && lst2){
            return lst1.map((revenue:number,ind:number) => revenue  / lst2[ind]);
        }
        else return [];
    }
    
    useEffect(()=>{
        const updatedFormsValue = generateInitialValues(FYCount, parsedFinancialSpreading);
        form.setFieldsValue(updatedFormsValue);
        setRerenderCnt((oldVal:number)=>oldVal+1); // form change is not rerendering the form so using this to force rerender
        const idealColSpan = Math.floor(24/(FYCount + CYACount + 1));
        const tempLabelColSpan = Math.max(5,idealColSpan);
        setColSpan(Math.floor((24-tempLabelColSpan) / (FYCount + CYACount)));
        setLabelColSpan(tempLabelColSpan);
    },[FYCount, CYACount]);

    useEffect(()=>{
        form.setFieldsValue(generateInitialValuesFromResponse(CYACount + FYCount, parsedFinancialSpreading));
    },[ratioAnalysis])


    useEffect(()=>{
        if(assessmentFormFlag && assessmentFormFlag !== FORM_ID){
            setViewModeActive(true);
        }
        else{
            setViewModeActive(false);
        }
    },[assessmentFormFlag]);

    useEffect(()=>{
        if(submitForm===true && assessmentFormFlag === FORM_ID){
            setSubmitForm(false);
            onFinish();
        }
    },[submitForm])
    
    const onFinish = async () => {
        try {
            const values = await form.validateFields();
            if(ratioAnalysis?.id){
                const formattedData = formatUpdateRatioAnalysisFormData(form.getFieldsValue(true), calenderYear, FYCount, CYACount, assessmentYearWithDateAndMonth);
                await sendUpdateRatioAnalysisFormData(formattedData,ratioAnalysis?.id, creditRequestId, parentAssessmentFrom);
                setCollapseRatioAnalysis(true);
            }
            else{
                const formattedData = formatRatioAnalysisFormData(form.getFieldsValue(true), calenderYear, FYCount, CYACount, assessmentYearWithDateAndMonth);
                await sendRatioAnalysisFormData(formattedData, creditRequestId, parentAssessmentFrom);
            }
            
            setReloadComponentCnt(Math.random());
        } catch (error) {
            console.error('Error while sending form data:', error);
        }
    };

    
     // Parse ratioAnalysis data if available
     const parsedFinancialSpreading = ratioAnalysis ? {
        ...ratioAnalysis,
        revenueGrowthPercentage: revenueGrowthList,
        netProfit: netProfitGrowthList,
        inventoryTurnoverRatio: ratioAnalysis ? JSON.parse(ratioAnalysis.inventoryTurnoverRatio) : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        tradeReceivableTurnoverRatio: ratioAnalysis ? JSON.parse(ratioAnalysis.tradeReceivableTurnoverRatio) : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        tradePayableTurnoverRatio: ratioAnalysis ? JSON.parse(ratioAnalysis.tradePayableTurnoverRatio) : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        netFixedAssets: salesAndNetFixedAssetsGrowthList,
    } : undefined;

    const generateInititalValue = (values: any[])=>{
        if(values.length < FYCount){
            const resList = [...values, ...Array.from({ length: FYCount-values.length }, () => 0)];
            return resList;
        }
        else{
            const res = values.filter((val:number, ind:number)=>ind<FYCount);
            return res;
        }
    }

    const generateInitialValues = (FYCount: number, ratioAnalysis?: any) => ({
        revenueGrowthPercentage: revenueGrowthList ? revenueGrowthList : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        netProfit: netProfitGrowthList ? netProfitGrowthList : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        inventoryTurnoverRatio: ratioAnalysis ? generateInititalValue(ratioAnalysis.inventoryTurnoverRatio) : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        tradeReceivableTurnoverRatio: ratioAnalysis ? generateInititalValue(ratioAnalysis.tradeReceivableTurnoverRatio) : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        tradePayableTurnoverRatio: ratioAnalysis ? generateInititalValue(ratioAnalysis.tradePayableTurnoverRatio) : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        netFixedAssets: salesAndNetFixedAssetsGrowthList ? salesAndNetFixedAssetsGrowthList : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
    });
    const generateInitialValuesFromResponse = (FYCount: number, ratioAnalysis?: any) => ({
        revenueGrowthPercentage: revenueGrowthList ? revenueGrowthList : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        netProfit: netProfitGrowthList ? netProfitGrowthList : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        inventoryTurnoverRatio: ratioAnalysis ? ratioAnalysis.inventoryTurnoverRatio : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        tradeReceivableTurnoverRatio: ratioAnalysis ? ratioAnalysis.tradeReceivableTurnoverRatio : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        tradePayableTurnoverRatio: ratioAnalysis ? ratioAnalysis.tradePayableTurnoverRatio : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
        netFixedAssets: salesAndNetFixedAssetsGrowthList ? salesAndNetFixedAssetsGrowthList : Array.from({ length: assessmentYearWithDateAndMonth.length }, () => 0),
    });
    

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: '#FFF',marginTop:'20px', borderRadius:'8px' }}>
                <div style={{padding:'20px'}} onClick={() => setCollapseRatioAnalysis((oldState) => !oldState)}>
                    <span>
                        {collapseRatioAnalysis ? <ArrowRight /> : <ArrowDown />}
                    </span>
                    <span style={{ color: '#242424', fontSize: '14px', fontWeight: '700', marginLeft: '10px' }}>
                        Ratio Analysis
                    </span>
                </div>
                { !collapseRatioAnalysis && (
                    <>
                    <Form
                        form={form}
                        style={{ marginTop:'10px', borderRight: '1px #E8E8E8 solid', borderLeft: '1px #E8E8E8 solid', padding: '0px 7px 0px 7px' }}
                        onFinish={onFinish}
                    >
                        {/* <Row gutter={16} style={{ backgroundColor: '#CEE6FA', padding: '15px 10px 15px 16px' }}>
                            <Col span={labelColSpan}></Col>
                            {Array.from({ length: FYCount }, (_, ind) => (
                                <Col span={colSpan} key={ind} style={{ fontWeight: '600' }}>
                                    {calenderYear ? `31 Dec ${2024 - ind}` :`31 Mar ${2024 - ind}`}
                                </Col>
                            ))}
                        </Row> */}

                        <Row gutter={16} style={{ backgroundColor: '#CEE6FA', padding: '15px 10px 15px 16px' }}>
                                <Col span={labelColSpan}></Col>
                                {
                                    <>
                                        { 
                                            assessmentYearWithDateAndMonth.map((data:string) => <Col span={colSpan} style={{ fontWeight: '600' }}>
                                                    {data}
                                                </Col>)
                                        }
                                    </>
                                }
                        </Row>
                        
                        <OtherRatioAnalysisComponent FYCount={FYCount} form={form} colSpan= {colSpan} labelColSpan={labelColSpan} 
                            viewModeActive={viewModeActive} assessmentYearWithDateAndMonth={assessmentYearWithDateAndMonth}/>
                    </Form>
                    {/* {
                        !viewModeActive &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100px', marginRight: '10px' }}
                                onClick={onFinish}
                            >
                                Proceed
                            </Button>
                        </div>
                    } */}
                    </>
                )}
                
            </div>
        </>
    );
};

export default RatioAnalysis;
