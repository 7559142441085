import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Typography, Row, Col, Tooltip, Empty } from 'antd';
import { EyeInvisibleFilled } from '@ant-design/icons';
import { ReactComponent as PipeIcon } from '../../../../utils/icons/Pipe_Icon.svg'; 
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import moment from 'moment';
import { getRequest } from '../../../../utils/handler/apiHandler';
import { useNavigate } from "react-router-dom";
import DisabledContext from 'antd/es/config-provider/DisabledContext';

const { Text } = Typography;

interface DocumentData {
    key: number;
    uploadedAt: string;
    expiryAt: string;
    uploadedBy: string;
    isExpired: boolean;
    documentUrl: string;
    fileName: string;
}

interface DocumentTableModalProps {
    isModalVisible: boolean;
    setIsModalVisible: (isModalVisible: boolean) => void;
    requestId: string;
    isClientQuote?: boolean,
    clientQuoteFileList: any
}

const DocumentTableModal: React.FC<DocumentTableModalProps> = ({ setIsModalVisible, isModalVisible, requestId,isClientQuote,clientQuoteFileList }) => {
    const [selectedDoc, setSelectedDoc] = useState<string>('');
    const [selectedDocName, setSelectedDocName] = useState<string>('');
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [data, setData] = useState<DocumentData[]>([]);
    const navigate = useNavigate();
    const fileTypeSet = new Set(['docx','doc', 'pptx', 'ppt', 'xlsx', 'xls','csv']);                                                                                        

    console.log("clientQuoteFileList", clientQuoteFileList);
    useEffect(() => {
        if(!isClientQuote){
            const fetchFiles = async () => {
                try {
                    const res = await getRequest(
                        `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/getPiDocuments?requestId=${requestId}`,
                    );
    
                    if (res.status === 404 || res.status === 400) {
                        navigate("/dashboard/prospect");
                        return;
                    }
    
                    const formattedData = res.data.map((item: any, index: number) => {
                        return {
                            key: index + 1,
                            uploadedAt: item.createdAt === null ? "NA" : moment(item.createdAt).format('h:mm A, DD MMM YYYY'),
                            expiryAt: item.validity === null ? "NA" : moment(item.validity).format('DD MMM YYYY'),
                            uploadedBy: item.createdBy === null ? "NA" : JSON.parse(item.createdBy).name,
                            isExpired: item.expired,
                            remarks : item.remarks === null ? "" : item.remarks,
                            documentUrl: item.url === null ? "" : item.url,
                            fileName: item.fileName === null ? "Doc" : item.fileName,
                        };
                    });
    
                    setData(formattedData);
                } catch (error) {
                    console.error("Error uploading document:", error);
                }
            };
    
            if (isModalVisible) {
                fetchFiles();
            }
        } else {
            const formattedData = clientQuoteFileList?.map((item: any, index: number) => {
                return {
                    key: index + 1,
                    uploadedAt: "",
                    expiryAt: "",
                    uploadedBy: "",
                    isExpired: "",
                    remarks : "",
                    documentUrl: item.url === null ? "" : item.url,
                    fileName: item.name === null ? "Doc" : item.name,
                };
            });

            setData(formattedData);
        }
        
    }, [isModalVisible, requestId, navigate]);

    const handleViewClick = (record: DocumentData) => {
        console.log(record)
        const fileExtension = record.documentUrl ? record.documentUrl.split('.').pop() : '';
        setSelectedDoc(record.documentUrl === selectedDoc ? "" : record.documentUrl);
        setSelectedDocName(record.documentUrl === selectedDoc ? "" : record.fileName);
        setIsPreviewVisible(record.documentUrl !== selectedDoc);
    };

    const handleDownlaod = async (record: any) => {
        const url = `${record.documentUrl}`;  // Replace with your file URL
        console.log(url)
    
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            console.log(blob)
            const downloadUrl = window.URL.createObjectURL(blob);
    
            // Create a temporary anchor element
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', url.split('/').pop() || 'downloaded_image.png');  // Specify the file name dynamically
    
            // Append the anchor element to the body
            document.body.appendChild(link);
    
            // Create a click event
            const event = new MouseEvent('click');
            link.dispatchEvent(event);
    
            // Clean up: remove the anchor element and revoke the object URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const columns = [
        {
            title: 'S. No.',
            dataIndex: 'key',
            key: 'id',
            width: '8%',
        },
        {
            title: 'Uploaded at',
            dataIndex: 'uploadedAt',
            hidden: isClientQuote,
            key: 'uploadedAt',
            sorter: (a: DocumentData, b: DocumentData) => moment(a.uploadedAt, 'h:mm A, DD MMM YYYY').unix() - moment(b.uploadedAt, 'h:mm A, DD MMM YYYY').unix(),
            width: '17%',
        },
        {
            title: 'File Name',
            dataIndex: 'fileName',
            hidden: !isClientQuote,
            key: 'fileName',
            width: '17%',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
            hidden: isClientQuote,
            key: 'uploadedBy',
            width: '18%',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            hidden: isClientQuote,
            key: 'remarks',
            render: (text: string, record: DocumentData) => (
                <Tooltip title = {text} placement="topLeft">
                    <Typography style={{overflow: "hidden", textOverflow: 'ellipsis', whiteSpace : 'nowrap', width: 'auto'}}>
                    {text}
                    </Typography>
              </Tooltip>
            ),
            width: '30%',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: DocumentData) => (
                <span>
                    <Button 
                        disabled={fileTypeSet.has(record?.documentUrl.split(".").pop() || "")}
                        type="link" 
                        style={{ boxShadow: "none", color: fileTypeSet.has(record?.documentUrl.split(".").pop() || "")?'grey':'#2C66E3', backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 500, paddingLeft:0}} 
                        icon={fileTypeSet.has(record?.documentUrl.split(".").pop() || "") ? <EyeInvisibleFilled/>    :(selectedDoc === record.documentUrl ? <EyeInvisibleFilled /> : <EyeIcon />)} 

                        onClick={() =>{console.log(record?.documentUrl.split(".").pop()); handleViewClick(record)}}
                    >
                    </Button>
                    <span><PipeIcon /></span>
                    <Button 
                        type="link" 
                        icon={<DownloadIcon style={{ fontWeight: "200px" }} />}
                        style={{ boxShadow: "none", color: '#2C66E3', backgroundColor: 'transparent', border: 'none', fontSize: '14px', fontWeight: 500,  }} 
                        onClick={() => handleDownlaod(record)}
                    >
                    </Button>
                </span>
            ),
            width: '13%',
        },
    ];

    return (
        <>
            <Modal
                title="Uploaded Proforma Invoice"
                centered
                afterClose={() => { setIsPreviewVisible(false);}}
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => {
                    setIsModalVisible(false);
                    setSelectedDoc("");
                    setSelectedDocName("");
                }}
                width={isPreviewVisible?'90%':'70%'}
                footer={null}
            >   
            {
                data?.length > 0 
                ? <Row>
                    <Col span={isPreviewVisible ? 14 : 24}>
                        <div className='custom-table'>
                        <Table
                            scroll={{ y: 300 }}
                            dataSource={data}
                            columns={columns}
                            pagination={false}
                            rowKey="key"  
                        />
                        </div>
                    </Col>
                    {isPreviewVisible && (
                        <Col span={10}>
                            <div style={{ border: '1px solid #ddd', padding: '16px' }}>
                                <h3>{selectedDocName} Preview</h3>
                                <iframe src={selectedDoc} width="100%" height="400px" title="Document Preview"></iframe>
                            </div>
                        </Col>
                    )}
                </Row>
                : <Empty description={"No PI found!"} />
            }
            </Modal>
        </>
    );
};

export default DocumentTableModal;
