// CreditRequestFormComponent.tsx

import React, { useEffect, useState } from 'react';
import FinancialSpreading from './utils/FinancialSpreading/FinancialSpreading';
import RatioAnalysis from './utils/RatioAnalysis/RatioAnalysis';
import RiskMatrix from './utils/RiskMatrix/RiskMatrix';
import { getRequest } from '../../../../../utils/handler/apiHandler';

interface CreditRequestFormComponentProps {
    FYCount: number;
    setFYCount: (cnt:number)=> void;
    calenderYear: boolean;
    setCalenderYear: (calenderYear: boolean) => void;
    creditRequestId: number;
    submitForm: boolean;
    setSubmitForm: (flag: boolean)=>void;
    assessmentFormFlag: number;
    setAssessmentFormFlag: (flagCnt: number)=>void;
    setShowMoveToCommitteeReview: (show: boolean)=>void;
    setShowDownloadCAM: (show: boolean)=>void;
    setShowDownloadSanctionLetter: (show: boolean)=>void;
    setShowEditDetail: (show: boolean)=>void;
    reloadComponentCnt: number;
    setReloadComponentCnt:(cnt: number)=>void;
    onHold: boolean;
    parentAssessmentFromFlag: boolean;
    CYACount : number;
    setCYACount : (count : number) => void;
    CYADateRanges : any[];
    setCYADateRanges : (range : any) => void;
}

const CreditRequestFormComponent: React.FC<CreditRequestFormComponentProps> = ({ FYCount, setFYCount, calenderYear, setCalenderYear, creditRequestId, submitForm, 
        setSubmitForm, assessmentFormFlag, setAssessmentFormFlag, setShowMoveToCommitteeReview, setShowDownloadCAM, setShowDownloadSanctionLetter, 
        setShowEditDetail, reloadComponentCnt, setReloadComponentCnt, onHold, parentAssessmentFromFlag, CYACount, setCYACount, CYADateRanges, setCYADateRanges }) => {
    const [creditRequestData, setCreditRequestData] = useState<any>();
    const [assessmentYearWithDateAndMonth, setAssessmentYearWithDateAndMonth] = useState<string[]>([]);
    
    useEffect(()=>{
        const fetchCreditRequestData = async ()=>{
            try{
                if(parentAssessmentFromFlag) {
                    const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit?creditRequestId=${creditRequestId}`);
                    setCreditRequestData(response?.data?.parentCreditAssessment);
                    const buttonFlagDetail = JSON.parse(response.data?.parentCreditAssessment?.buttonFlagDetail);
                    setAssessmentFormFlag(buttonFlagDetail['Form flag'])
                    setShowMoveToCommitteeReview(buttonFlagDetail['Move to committee review'] === 1 ? true : false);
                    setShowDownloadCAM(buttonFlagDetail['Download CAM template'] === 1 ? true : false);
                    setShowDownloadSanctionLetter(buttonFlagDetail['Download template'] === 1 ? true : false);
                    setShowEditDetail(buttonFlagDetail['Edit detail'] === 1 ? true : false);
                    const shareCapitalString = response.data?.parentCreditAssessment?.financialSpreading?.shareCapital;
                    const shareCapitalList = shareCapitalString ? JSON.parse(shareCapitalString) : [];
                    let CYACountFromResponse = response.data?.parentCreditAssessment?.financialSpreading?.cyaCount;
                    // CYACountFromResponse = 2;
                    setCYACount(CYACountFromResponse || 0);
                    setAssessmentYearWithDateAndMonth(JSON.parse(response.data?.parentCreditAssessment?.financialSpreading?.assessmentYearInfo));
                    if(shareCapitalList.length!==0) setFYCount(shareCapitalList.length - CYACountFromResponse);
                    const isCalenderYearAssessment = response.data?.parentCreditAssessment?.financialSpreading?.isCalenderYearAssessment;
                    if(isCalenderYearAssessment) setCalenderYear(isCalenderYearAssessment)
                } else {
                    const response = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit?creditRequestId=${creditRequestId}`);
                    setCreditRequestData(response?.data?.childCreditAssessment);
                    const buttonFlagDetail = JSON.parse(response.data?.childCreditAssessment?.buttonFlagDetail);
                    setAssessmentFormFlag(buttonFlagDetail['Form flag'])
                    
                    setShowMoveToCommitteeReview(buttonFlagDetail['Move to committee review'] === 1 ? true : false);
                    setShowDownloadCAM(buttonFlagDetail['Download CAM template'] === 1 ? true : false);
                    setShowDownloadSanctionLetter(buttonFlagDetail['Download template'] === 1 ? true : false);
                    setShowEditDetail(buttonFlagDetail['Edit detail'] === 1 ? true : false);
                    const shareCapitalString = response.data?.childCreditAssessment?.financialSpreading?.shareCapital;
                    const shareCapitalList = shareCapitalString ? JSON.parse(shareCapitalString) : [];
                    let CYACountFromResponse = response.data?.childCreditAssessment?.financialSpreading?.cyaCount;
                    // CYACountFromResponse = 2;
                    setCYACount(CYACountFromResponse || 0);
                    setAssessmentYearWithDateAndMonth(JSON.parse(response.data?.childCreditAssessment?.financialSpreading?.assessmentYearInfo));
                    if(shareCapitalList.length!==0) setFYCount(shareCapitalList.length - CYACountFromResponse);
                    const isCalenderYearAssessment = response.data?.childCreditAssessment?.financialSpreading?.isCalenderYearAssessment;
                    if(isCalenderYearAssessment) setCalenderYear(isCalenderYearAssessment)
                }
            }
            catch( error) {
                console.error('Error while fetching creditRequestData:', error);
            }
        }
        fetchCreditRequestData();
    },[reloadComponentCnt, parentAssessmentFromFlag]);

    
    return (
        <>
            <FinancialSpreading  FYCount = {FYCount} calenderYear={calenderYear} creditRequestId={creditRequestId} assessmentFormFlag={assessmentFormFlag}
                financialSpreading={creditRequestData?.financialSpreading} submitForm={submitForm} setSubmitForm={setSubmitForm} setReloadComponentCnt={setReloadComponentCnt} 
                parentAssessmentFrom={parentAssessmentFromFlag} CYACount={CYACount} setCYACount={setCYACount} assessmentYearWithDateAndMonth={assessmentYearWithDateAndMonth}
                 setAssessmentYearWithDateAndMonth={setAssessmentYearWithDateAndMonth} CYADateRanges={CYADateRanges} setCYADateRanges={setCYADateRanges}/>
            { (assessmentFormFlag >2 || assessmentFormFlag === 2 && !onHold)  &&     
                <RatioAnalysis  FYCount = {FYCount} CYACount={CYACount} calenderYear={calenderYear} creditRequestId={creditRequestId} assessmentFormFlag={assessmentFormFlag} 
                    ratioAnalysis={creditRequestData?.ratioAnalysis} creditRequestData={creditRequestData} submitForm={submitForm} 
                    setSubmitForm={setSubmitForm} setReloadComponentCnt={setReloadComponentCnt} parentAssessmentFrom={parentAssessmentFromFlag} 
                    assessmentYearWithDateAndMonth={assessmentYearWithDateAndMonth} />
            }
            { (assessmentFormFlag >3 || assessmentFormFlag === 3 && !onHold)  &&     
                <RiskMatrix creditRequestId={creditRequestId} assessmentFormFlag={assessmentFormFlag} riskMatrix={creditRequestData?.riskMatrix} creditRequestData={creditRequestData}
                    submitForm={submitForm} setSubmitForm={setSubmitForm} setReloadComponentCnt={setReloadComponentCnt} parentAssessmentFrom={parentAssessmentFromFlag} />
            }
        </>
    );
};

export default CreditRequestFormComponent;
