import { Empty, Modal, Typography } from 'antd';
import React from 'react';

interface PreviewFileURLProps {
    fileType: string;
    url: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
} 
const previewFileList = ["application/pdf", "image/jpeg", "image/jpg", "image/png"];
const PreviewFileURL: React.FC<PreviewFileURLProps> = ({ fileType, url, showModal, setShowModal }) => {

    console.log("previewFileList", previewFileList.includes("application/pdf"), fileType)
    return <Modal
        title={<Typography style={{fontSize: 16, fontWeight: 500, color: "#404040"}}>Preview File</Typography>}
        centered
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
    >
        {
            previewFileList.includes(fileType) 
            ? <iframe src={url} style={{ width: "100%", height: "60vh", border: "none" }} /> 
            : <><iframe src={url} style={{ display: "none" }} /><Empty description={"No preview available"} /></>
        }
    </Modal>
}

export default PreviewFileURL;