import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { LeftOutlined, MinusOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ReactComponent as EyeIcon } from '../../../../utils/icons/view_icon.svg';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { Table, Spin, Tooltip, Select, Tag, Typography, Row, Button, Col, Input, Space, Checkbox, Tabs } from "antd";
import { handlePageChange, handlePageSizeChange } from "../../Prospect/utils/paginationFunctions";
import { getRequest } from "../../../../utils/handler/apiHandler";
import FilterDropdown from "../../Client/FilterDropDown";
import { getFormattedDate } from "../../../../utils/helpers/utils";
import moment from "moment";

const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
};

interface propType {
    showConfirmed: boolean
}

const tableHeight = 600

export default function ClientQuoteList({clientQuoteId}:any) {
    const defaultSort = ""
    const defaultFilter = `cq.client_quote_id|eq|${clientQuoteId}`
    const defaultParams = `&sort=${encodeURIComponent(defaultSort)}&filterAnd=${encodeURIComponent(defaultFilter)}`


    const tableRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [sortOrder, setSortOrder] = useState<string>(``); // Default sort
    const [clientNameOptions, setClientNameOptions] = useState([]);
    const [ownerList, setownerList] = useState([]);
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const defaultData: any[] = [];
    const [data, setData] = useState<any[]>(defaultData);
    const [statusList, setStatusList] = useState<any[]>([]);

    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    useEffect(() => {
        fetchAllRequestedQuoteTableData();
    }, [currentPage, pageSize, sortOrder]);

    const fetchQuoteStatusList = async (): Promise<{ data: any[]} | null> => {
        try {
          const res = await getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/master/paymentStatus`);
          if (res.data) {
            return res.data;
          }
          return null; // Return null if data is missing in the response
        } catch (error) {
          console.error('Error fetching StagesList:', error);
          return null; // Return null in case of an error
        }
      };

      const fetchQuoteStatusData = async (setStatusList: any) => {
        try {
          const statusListResponse: any = await fetchQuoteStatusList();
          if (statusListResponse) {
            setStatusList(statusListResponse);
          }
        } catch (error) {
          // Handle errors if any
          console.error('Error fetching data:', error);
        }
      };

    useEffect(() => {
        fetchQuoteStatusData(setStatusList);
      },[]);

    const fetchAllRequestedQuoteTableData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/getAllChildQuotes?page=${currentPage}&size=${pageSize}${sortOrder ? sortOrder : defaultParams}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                console.log("res data", res.data)
                setData(res.data.items);
                if ('totalPages' in res.data) {
                    setPageCount(res.data.totalPages);
                }
                if ('totalItems' in res.data) {
                    setTotalRecord(res.data.totalItems);
                }
            } else {
                console.error('Error fetching Requested Quotes Table List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Requested Quotes data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getColumns = (): ColumnsType<any> => [
        {
            title: "Quote ID",
            dataIndex: "clientQuoteId",
            key: "clientQuoteId",
        },
        {
            title: "Order Confirmation Date",
            dataIndex: 'orderConfirmationDate',
            key: 'lem.id',
            render: (obj : string) => {
                const formattedDate = moment(obj).format("DD MMM YYYY");
                return(
                  formattedDate
                );
            }
        },
        {
            title: "Asset Assign Date",
            dataIndex: 'assignedDate',
            key: 'assignedDate',
            render: (obj : string) => {
                const formattedDate = obj ? moment(obj).format("DD MMM YYYY") : "";
                return(
                  formattedDate
                );
            }
        },
        // {
        //     title: "Owned By",
        //     dataIndex: "ownedBy", //audit infio->updaed by
        //     key: "ownedBy",
        //     width: "10%",
        // },
        {
            title: "Assets Assigned By",
            dataIndex: "assignedBy",
            key: "assignedBy",
            // sorter: { multiple: 2 },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            // sorter: { multiple: 2 },
        },
        {
            title: "Asset No.",
            dataIndex: "assetNumber",
            key: "assetNumber",
            // sorter: { multiple: 2 },
        },
    ];


    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
        let sortString = '';
        let filterString = ""

        if (Array.isArray(sorter)) {
            // Handle multiple sorts
            sortString = sorter
                .filter(s => s.order) // Filter out columns without sorting
                .map(s => `${s.field}|${s.order === 'ascend' ? "ASC" : "DESC"}`) // Map each sorter to the format field:asc/desc
                .join(',');
        } else if (sorter.order) {
            // Handle single sort
            const sortField = sorter.field
            const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
            sortString = `${sortField}|${sortOrder}`;
        }
        console.log("prospect filter", filters)
        if (sortString && sortString !== "") {
            sortString = "&sort=" + encodeURIComponent(sortString + "&" + defaultSort)
        } else {
            sortString = "&sort=" + encodeURIComponent(defaultSort)
        }
        
        
        const filterEntries = Object.entries(filters)
            .filter(([key, value]) => Array.isArray(value) && value.length > 0) // Ensure it's a valid array with values
            .map(([key, values]:any) => values.map((v: string | number) => `${key}|eq|${v}`).join('&')) // Format each filter entry
            .join('&'); // Combine all filters

        if (filterEntries) {
            filterString = `&filterOr=${encodeURIComponent(filterEntries + "&" + defaultFilter)}`;
        } else {
            filterString = `&filterOr=${encodeURIComponent(defaultFilter)}`;
        }
        console.log("filterString", filterString)

        // Set the sort order string for the API call
        setSortOrder(sortString + filterString);
    };

    return (
        <>

            <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
                <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                    <Spin spinning={loading} size="large">
                        <Table
                            className="lev-table"
                            columns={getColumns()}
                            dataSource={data || []}
                            pagination={{
                                pageSize: pageSize,
                                position: ["bottomRight"],
                                showSizeChanger: false,
                                showTotal: (total, range) => (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '-4px' }}>
                                        <div style={{ fontSize: '1rem' }}>
                                            <span style={{ fontSize: 14, fontWeight: 400 }}>Showing</span>
                                            <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                                <Select.Option value="10">10</Select.Option>
                                                <Select.Option value="20">20</Select.Option>
                                                <Select.Option value="50">50</Select.Option>
                                                <Select.Option value="100">100</Select.Option>
                                            </Select>
                                            <span style={{ fontSize: 15, fontWeight: 400 }}>per page</span>
                                            <span style={{ fontSize: 15, fontWeight: 400, marginRight: '10px', marginLeft: '10px', color: 'grey' }}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                            <span style={{ fontSize: 14, fontWeight: 400 }}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                                        </div>
                                    </div>
                                ),
                                onChange: handlePageChangeLocal,
                                current: currentPage,
                                total: totalRecord,
                                itemRender: (current, type, originalElement) => {
                                    if (type === 'prev') {
                                        return <LeftOutlined style={{ marginLeft: '4rem', fontSize: 12, fontWeight: 500 }} />;
                                    }
                                    if (type === 'next') {
                                        return <RightOutlined style={{ fontSize: 12, fontWeight: 500 }} />;
                                    }
                                    if (type === 'page') {
                                        return (
                                            <span style={{ cursor: 'pointer', fontSize: 12, fontWeight: 500 }} onClick={() => handlePageChangeLocal(current)}>
                                                {current}
                                            </span>
                                        );
                                    }
                                    if (type === 'jump-next' || type === 'jump-prev') {
                                        return <a style={{ color: 'black', fontSize: 12, fontWeight: 500 }}><strong> ... </strong></a>;
                                    }
                                    return originalElement;
                                },
                            }}
                            onChange={handleTableChange} // Handle sort
                            scroll={{ x: 1700, y: 0.80 * tableHeight }}
                            size="middle"
                        // components={{
                        //     header: {
                        //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                        //     },
                        //   }}
                        />
                    </Spin>
                </div>
            </div>
        </>
    )
}