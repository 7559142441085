import { Button, Card, Divider, Row, Spin, Tag, Typography } from "antd";
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';
import { LoadingOutlined } from "@ant-design/icons";
import { ADMIN, PROCOUREMENT_MANAGER, PROCOUREMENT_HEAD, AS_MANAGER, AS_HEAD} from "../../constants";
import { getFormattedDate } from "../../../../utils/helpers/utils";
import { useEffect, useRef, useState } from "react";
import ViewProof from "../../Prospect/components/ViewProof";

const { Title } = Typography;
const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

interface RequestedQuoteDetailsProps {
    loader: boolean;
    details: any;
    setShowRejectModal: (showRejectModal: boolean) => void;
    setShowETAModal: (showETAModal: boolean) => void;
    setStatusId: (statusId: any) => void;
    setTitle: (title: any) => void;
    setSubTitle: (subTitle: any) => void;
    acceptLoader: boolean;
    user: any;
    allowedActionsForSampleQuote: Set<string>;
}

const RequestedQuoteDetails: React.FC<RequestedQuoteDetailsProps> = ({ 
    loader, 
    details, 
    setShowRejectModal,
    setShowETAModal,
    setStatusId, 
    acceptLoader, 
    user, 
    setTitle, 
    setSubTitle,
    allowedActionsForSampleQuote,
    }) => {

    const [showViewDocs, setShowViewDocs] = useState<boolean> (false);
    const [documentActionTargetId, setDocumentActionTargetId] = useState<number> (-1);

    const handleAcceptRequest = () => {
        setTitle("Enter PI document collection ETA");
        setSubTitle("Enter the time, it will take you to provide PI document for the request");
        setShowETAModal(true);
        setStatusId(2)
    }

    const handleProceedQuoteCreation = () => {
        setTitle("Enter quote completion ETA");
        setSubTitle("Enter the time, it will take you to complete the sample quote request");
        setShowETAModal(true); 
        setStatusId(4)
    }

    return (
        <>
        <Title style={{marginTop: 30, fontWeight: 700}} level={5}>Quote Details</Title>
        <Card bordered={false} style={{marginTop: 20}}>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <div className="requirement-card-info">
            <Typography className="description-label">Vehicle Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.vehicleType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Vehicle Category</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.vehicle && JSON.parse(details?.vehicle).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Make</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.vehicle && JSON.parse(details?.make).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Model</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.vehicle && JSON.parse(details?.model).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Variant</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.vehicle && JSON.parse(details?.variant).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Buying City</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.buyingCity && JSON.parse(details?.buyingCity).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">GEVL State</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.state && JSON.parse(details?.state).name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Registered To</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.registeredTo
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Registration Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.registrationType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Payment Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.paymentType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Insurance Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.insuranceType
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Road Tax Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.roadTaxType || "No data"
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">No of Vehicles</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.vehicleCount
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Tenure in Month</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.tenure && JSON.parse(details.tenure)?.name
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Mileage per month(KM)</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.mileage
                }</span>
            </Typography>
            </div>
            <div className="requirement-card-info">
            <Typography className="description-label">Lease Type</Typography>
            <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                <span style={{display: loader?"none":""}}>{
                    loader ? "" : details?.leaseType
                }</span>
            </Typography>
            </div>
            {
               ((details?.accessories && details?.accessories !== "[]") || (details?.services && details?.services !== "[]")) &&
                <Divider orientation="left" orientationMargin={0}><span style={{fontSize: 14, fontWeight: 500, margin: 0}}>Accessories & Services</span></Divider>
            }
            { (details?.accessories && JSON.parse(details?.accessories).length !== 0) && <div className="requirement-card-info" style={{width: "50%"}}>
                <Typography className="description-label">Accessories</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : JSON.parse(details?.accessories).map((e: any) => {
                            return <Tag style={{marginBottom: 5}}>{e.name}</Tag>
                        })
                    }</span>
                </Typography>
            </div> }
            { (details?.services && JSON.parse(details?.services).length !== 0) &&  <div className="requirement-card-info" style={{width: "50%"}}>
                <Typography className="description-label">Services</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : JSON.parse(details?.services).map((e: any) => {
                            return <Tag style={{marginBottom: 5}}>{e.name}</Tag>
                        })
                    }</span>
                </Typography>
            </div> 
            }
            {
                details?.remark && <div className="requirement-card-info" style={{width: "100%"}}>
                <Typography className="description-label">Prospect Requirement Remark</Typography>
                <Typography className={`${loader ? "shimmer-ui" : ""}`} style={{fontSize: 14, minHeight: 15}}>
                    <span style={{display: loader?"none":""}}>{
                        loader ? "" : details?.remark
                    }</span>
                </Typography>
                </div>
            }
            {
                
                (details?.quoteStatusId === 6 || details?.quoteStatusId === 7 || details?.quoteStatusId === 8 || (details?.quoteStatusId === 2 && details?.backToOpsReviewRemark)) &&
                <Divider className="requirement-card-info" orientation="left" orientationMargin={0}><span style={{fontSize: 14, fontWeight: 500, margin: 0}}>Quote Status Details</span></Divider>
            }
            {
                (details?.quoteStatusId === 6)
                &&
                <div>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                        <Typography style={{ fontSize: 12, fontWeight: 500 , paddingBottom : '5px'}}>
                          Cancelled by : {details.rejectCancelReviseBy && JSON.parse(details.rejectCancelReviseBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                          </span> Cancelled at : {details.rejectCancelReviseAt && getFormattedDate((new Date(details.rejectCancelReviseAt.split(" ")[0] + "T" + details.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                        </Typography>
                    </Typography>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                        <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                          Remark : </span> {details?.rejectCancelReviseRemark}
                        </Typography>
                    </Typography>
                </div> 
            }
            {
                (details?.quoteStatusId === 7)
                &&
                <div>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                        <Typography style={{ fontSize: 12, fontWeight: 500 , paddingBottom : '5px'}}>
                        Rejected by : {details.rejectCancelReviseBy && JSON.parse(details.rejectCancelReviseBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                          </span> Rejected at : {details.rejectCancelReviseAt && getFormattedDate((new Date(details.rejectCancelReviseAt.split(" ")[0] + "T" + details.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                        </Typography>
                    </Typography>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                        <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                          Remark : </span> {details?.rejectCancelReviseRemark}
                        </Typography>
                    </Typography>
                </div> 
            }
            {
                (details?.quoteStatusId === 2 && details?.backToOpsReviewRemark)
                &&
                <div>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                        <Typography style={{ fontSize: 12, fontWeight: 500 , paddingBottom : '5px'}}>
                        Reverted by : {details.backToOpsReviewBy && JSON.parse(details.backToOpsReviewBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                          </span> Reverted at : {details.backToOpsReviewAt && getFormattedDate(details?.backToOpsReviewAt)}
                        </Typography>
                    </Typography>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                        <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                          Remark : </span> {details?.backToOpsReviewRemark}
                        </Typography>
                    </Typography>
                </div> 
            }
            {
                (details?.quoteStatusId === 8)
                &&
                <div>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                        <Typography style={{ fontSize: 12, fontWeight: 500 , paddingBottom : '5px'}}>
                          Revise by : {details.rejectCancelReviseBy && JSON.parse(details.rejectCancelReviseBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                          </span> Revise at : {details.rejectCancelReviseAt && getFormattedDate((new Date(details.rejectCancelReviseAt.split(" ")[0] + "T" + details.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                        </Typography>
                    </Typography>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                        <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                          Remark :  </span> {details?.rejectCancelReviseRemark === null ? "Sample quote revised by some update in prospect requirement" : details?.rejectCancelReviseRemark}
                        </Typography>
                    </Typography>
                </div> 
            }
            {
                
                details?.quoteStatusId === 3 && details?.isReviseBypass &&
                <Divider className="requirement-card-info" orientation="left" orientationMargin={0}><span style={{fontSize: 14, fontWeight: 500, margin: 0}}>Quote Status Details</span>
                    {(details?.revisionProofDocCount > 0)  &&
                          <Button 
                          onClick={() => {
                            setShowViewDocs(true);
                            setDocumentActionTargetId(parseInt(details?.requirementId, 10));
                          }}
                            style={{boxShadow:"none" ,color : '#2C66E3', backgroundColor: 'transparent', border: 'none', fontSize: '12px', fontWeight: 500, paddingRight:"0px"}} > 
                          <EyeIcon style={{color : '#2C66E3', marginRight:'0.1rem', paddingTop:"0.3rem", height:"15px"}}/>
                            View Proof
                          </Button>
                          }
                </Divider>
            }
            { showViewDocs &&
                      <ViewProof showViewDocs={showViewDocs} setShowViewDocs={setShowViewDocs} documentActionTargetId={documentActionTargetId}/>
            }

            {
                details?.quoteStatusId === 3 && details?.isReviseBypass &&
                <div>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch'}}>
                        <Typography style={{ fontSize: 12, fontWeight: 500 , paddingBottom : '5px'}}>
                          Revise by : {details.rejectCancelReviseBy && JSON.parse(details.rejectCancelReviseBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                          </span> Revise at : {details.rejectCancelReviseAt && getFormattedDate((new Date(details.rejectCancelReviseAt.split(" ")[0] + "T" + details.rejectCancelReviseAt.split(" ")[1] + "Z")).toISOString())}
                        </Typography>
                    </Typography>
                    <Typography style={{display:'flex', alignItems:'flex-start', alignSelf:'stretch', paddingBottom : '20px'}}>
                        <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}>
                          Remark :  </span> {details?.rejectCancelReviseRemark === null ? "Sample quote revised by some update in prospect requirement" : details?.rejectCancelReviseRemark}
                        </Typography>
                    </Typography>
                </div> 
            }
          </div>
        </Card>
        <div style={{marginTop: 20, display: "flex", justifyContent: "flex-end"}}>
          {
          (details?.quoteStatusId === 1 || details?.quoteStatusId === 8)
          && 
          <div>
            {
                allowedActionsForSampleQuote?.has('rejectRequest') &&
                <Button
                    ghost
                    danger
                    type="primary"
                    onClick={_ => {setShowRejectModal(true)}}
                    icon={<CrossIcon />}
                    style={{marginLeft: 10}}
                    className="button-override danger"
                    > Reject Request
                </Button>
            }
            {
                
                allowedActionsForSampleQuote?.has('acceptRequest') &&
                <Button
                    type="primary"
                    onClick={handleAcceptRequest}
                    icon={acceptLoader ? <Spin indicator={antIcon} /> : <TickIcon style={{marginLeft: 10}}/>}
                    style={{marginLeft: 10}}
                    className="button-override"
                    > Accept Request
                </Button>
            }
            
          </div>
          }
          {
            details?.quoteStatusId === 3  && 
            <div>
                {
                    allowedActionsForSampleQuote?.has('rejectRequest') &&
                    <Button
                        ghost
                        danger
                        type="primary"
                        onClick={_ => {setShowRejectModal(true)}}
                        icon={<CrossIcon />}
                        style={{marginLeft: 10}}
                        className="button-override danger"
                    > Reject Request
                    </Button>
                }
                {
                    allowedActionsForSampleQuote?.has('createSampleQuoteProceed') &&
                    <Button
                    type="primary"
                    onClick={handleProceedQuoteCreation}
                    style={{marginLeft: 10}}
                    className="button-override"
                    >Proceed to sample quote <RightArrowIcon style={{marginLeft: 10}}/>
                    </Button>
                }
            </div>
            }
        </div>
        </>
        
     );
}

export default RequestedQuoteDetails;