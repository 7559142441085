import Icon from "@ant-design/icons";
import { Button, Col, Row, Tabs, Typography } from "antd";
import { ReactComponent as AddIcon } from '../../Dashboard/SVGs/add_icon.svg';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AllTicketList from "./AllTicketList";
import InServiceTicketList from "./InServiceTicketList";
import OverdueTicketList from "./OverdueTicketList";

const TabPane = Tabs.TabPane;

const TicketPageLayout: React.FC  = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const initialTab = query.get('tab');
  
    // State to manage the active tab
    const [activeKey, setActiveKey] = useState(initialTab || '1');

    useEffect(() => {
        if (initialTab) {
          setActiveKey(initialTab);
        }
    }, [initialTab]);
    
    return(
        <React.Fragment>
            <div style={{marginTop : '20px'}}>
                    <Row justify="space-between" align="top" style={{ marginLeft:'30px' , marginRight: '30px', marginBottom: 20 }}>
                        <Col>
                            <Typography style={{ fontSize: 20, fontWeight: 700 }}>Tickets</Typography>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                icon={<Icon component={AddIcon} />}
                                onClick={() => {}}
                                style={{ fontWeight: 450, marginTop: 15, marginLeft: 10, height: 35}}
                                >
                                    Create Ticket
                            </Button>
                        </Col>
                    </Row>

                <div className="container-body" style={{marginLeft : '30px', marginRight : '30px'}}>
                  <Tabs activeKey={activeKey} onChange={setActiveKey} className="tab" onTabClick={(e) => navigate(`?tab=${e}`)}>
                    <TabPane tab={<span className="tab-heading">All Tickets</span>} key="1">
                        <AllTicketList/>
                    </TabPane>
                    <TabPane tab={<span className="tab-heading">In Servicing</span>} key="2">
                        <InServiceTicketList/>
                    </TabPane>
                    <TabPane tab={<span className="tab-heading">Overdue</span>} key="3">
                        <OverdueTicketList/>
                    </TabPane>
                  </Tabs>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TicketPageLayout;