import { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Row, Space, Spin, Tooltip, Typography, Upload } from 'antd';
import { getRequest, postRequest, putRequest } from '../../../../utils/handler/apiHandler';
import { ReactComponent as DeleteIcon } from '../../SVGs/delete_icon.svg';
import { ReactComponent as TickMarkIcon } from "../../SVGs/tick_mark_icon.svg";
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as AddIcon } from '../../SVGs/add_icon.svg';
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';
import ViewDocumentModal from '../../Client/ViewDocumentModal';
import { DeleteFilled, EyeInvisibleFilled, EyeOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import _ from "lodash";
import axios from 'axios';
import { useAppSelector } from '../../../../app/hooks';
import { authSelector } from '../../../../features/auth/authSlice';
import dayjs from 'dayjs';
import currencyFormatter from '../../../../utils/NumberFormater';

interface PaymentModalProps {
    paymentTrancheList: any;
    setPaymentTrancheList: (paymentTrancheList: any) => void;
    paymentModal: boolean;
    setPaymentModal: (paymentModal: boolean) => void;
    data: any;
    clientData: any;
}

const antIconUpload = <LoadingOutlined style={{ fontSize: 16, color: "white", marginLeft: 6 }} spin />;

const PaymentModal: React.FC<PaymentModalProps> = ({ paymentTrancheList, setPaymentTrancheList, paymentModal, setPaymentModal, data, clientData }) => {
    const [form] = Form.useForm();
    const [loader, setLoader] = useState<boolean>(false);
    const auth = useAppSelector(authSelector);
    const [paymentInfo, setPaymentInfo] = useState<any>();
    const [previewFile, setPreviewFile] = useState<null | {
        "fileName": string,
        "type": string,
        "url": string,
    }>(null);
    const [isForm, setIsFrom] = useState<boolean>(true);
    const [submit, setSubmit] = useState<boolean>(true);
    const [exceed, setExceed] = useState<boolean>(false);
    const [fileList, setFileList] = useState<any>([]);
    const [fileUrls, setFileUrls] = useState<any>([]);
    const [uploading, setUploading] = useState(false);
    const [securityDeposit, setSecurityDeposit] = useState<any>();
    const [processingFee, setProcessingFee] = useState<any>();
    const [ongoingUploads, setOngoingUploads] = useState(0)

    const handleUploadRequest = async (file: any) => {
        const url = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadQuoteFile`;
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('prospectId', clientData?.prospectId);
        formData.append('type', 'payment');
        setUploading(true)
        setOngoingUploads((prev) => prev + 1);
        try {
            const response = await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${auth.accessToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                if (response.status === 200) {
                    setFileUrls((prevState: any) => [...prevState, response?.data?.DocumentUrl])
                    setFileList((prevState: any) => [...prevState, file.file]);
                }
                else {
                    console.log("error in uploading file")
                }
            })
        } catch (error) {
            console.error('Upload error:', error);
        } finally {
            setOngoingUploads((prev) => {
                const newOngoingUploads = prev - 1;
                if (newOngoingUploads === 0) {
                    setUploading(false);
                }
                return newOngoingUploads;
            });
        }
    };

    const handleDelete = (file: any) => {

        const tempFileList = [];
        const tempFileUrls: string[] = []
        for (let i = 0; i < fileList?.length; i++) {
            if (fileList[i]?.uid !== file?.uid) {
                tempFileUrls.push(fileUrls?.[i])
                tempFileList.push(fileList[i]);
            }
        }
        setFileUrls(tempFileUrls)
        setFileList(tempFileList);
    }

    const handleAddTranche = async () => {
        setLoader(true);
        try {
            const payload = {
                ...form.getFieldsValue(),
                transactionDate: dayjs(form.getFieldsValue().transactionDate).toISOString(),
                additionalCharges: !isArrayEmpty(form.getFieldsValue(true)) ? filterAndRestructure(form.getFieldsValue(true).additionalCharges) : [],
                clientFileDetails: fileUrls.map((e: any) => {
                    return {url: e};
                })
            }
            postRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/payment-confirmation/${clientData?.clientQuoteId}?prospectId=${clientData?.prospectId}`, payload).then(res => {
                message.destroy()
                if(res.status >= 400) {
                    console.log(res.err.response.data.errors[0])
                    message.error(res.err.response.data.errors[0]);
                } else {
                    form.resetFields();
                    setFileUrls([]);
                    setFileList([]);
                    setPaymentTrancheList(res?.data?.paymentConfirmationDetailTranches);
                    setFileUrls([]);
                    setSecurityDeposit(null);
                    setProcessingFee(null);
                    setPaymentModal(false);
                    
                    window.location.reload();
                }
                setLoader(false);
            })
        } catch (err) {
            setPaymentModal(false);
            setLoader(false);
            console.log("Error: ", err);
        }
    }

    const handleStatus = (statusId: number) => {
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/${clientData?.clientQuoteId}?statusId=${statusId}`, {}).then(res => {
            window.location.reload()
        })
    }

    useEffect(() => {
        if(paymentModal){
            clientData?.clientQuoteId && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/securityProcessingFees?clientQuoteId=${clientData?.clientQuoteId}`).then(res => {
                setPaymentInfo(res.data);
            });
        }
    }, [clientData, paymentModal]);

    const handleValueChange = () => {
        const formData = form.getFieldsValue(true);
        setSubmit(_.isNil(
            (formData.securityDepositReceived && !_.isNull(formData.securityDepositReceived)) || 
            (formData.processingFeeReceived && !_.isNull(formData.processingFeeReceived)) || 
            (formData.downPayment && !_.isNull(formData.downPayment)) || 
            (formData.utrNo && !_.isNull(formData.utrNo)) || 
            (formData.numberOfVehiclePaymentDoneFor && !_.isNull(formData.numberOfVehiclePaymentDoneFor)) || 
            (!Boolean(formData.remarks) && null) ||
            (isArrayEmpty(formData.additionalCharges) && null)
            ));
    }

    const isObjectEmpty = (obj: any) =>
        _.isPlainObject(obj) &&
        obj && Object.values(obj).every(value => _.isNil(value) || value === "");
      
      const isArrayEmpty = (arr: any) =>
        arr && Array.isArray(arr) && arr.every(item => _.isNil(item) || isObjectEmpty(item));

      const filterAndRestructure = (arr: any) =>
        _.chain(arr)
          .filter(
            (item) =>
              _.isPlainObject(item) && // Ensure item is an object
              !_.isEmpty(_.omitBy(item, _.isNil)) // Exclude empty objects
          )
          .map((item) => ({
            additionalChargesName: item.additionalChargesName || "Default Name", // Restructure with a default
            additionalChargesValue: item.additionalChargesValue || 0, // Set default value if null
          }))
          .value();


    useEffect(() => {
        setExceed((Math.round(paymentInfo?.total_processing_fee) - Math.round(paymentInfo?.received_processing_fee) - (processingFee || 0) < 0) || (Math.round(paymentInfo?.total_security_deposit || 0) - Math.round(paymentInfo?.received_security_deposit || 0) - (securityDeposit || 0) < 0))
    }
    ,[processingFee, securityDeposit]);

    return <Modal
        title={<span>Payment Confirmation Details</span>}
        centered
        open={paymentModal}
        onCancel={() => {setPaymentModal(false); form.resetFields(); setFileList([]); setFileUrls([]); setSecurityDeposit(null); setProcessingFee(null); }}
        footer={<>
            <Button
                ghost
                danger
                icon={<CrossIcon />}
                onClick={_ => { setPaymentModal(false); form.resetFields(); setFileList([]); setFileUrls([]); setSecurityDeposit(null); setProcessingFee(null); }}
                className="button-override danger"
            >
                Close
            </Button>
            <Button
                disabled={submit || exceed}
                type="primary"
                className="button-override"
                icon={loader ? <Spin indicator={antIconUpload} /> : <TickMarkIcon />}
                onClick={_ => {
                    !loader && handleAddTranche()
                }}
            >
                Confirm
            </Button>
        </>}
        className="preview-modal"
        width={1200}
        style={{ height: 750, overflow: "scroll" }}
    >
        <div style={{height: 600, overflow: "scroll"}}>
        <div style={{ display: "flex", justifyContent: "space-between", padding: 20, backgroundColor: "#E8F1FF", borderRadius: 6, marginTop: 20 }}>
            <div>
                <div className="info-text-label">Total Security Deposit</div>
                <div className="info-text-data">{_.isNil(paymentInfo?.total_security_deposit) ? "NA" : "₹" + currencyFormatter(paymentInfo?.total_security_deposit)}</div>
            </div>
            <div>
                <div className="info-text-label">Received Security Deposit</div>
                <div className="info-text-data">{_.isNil(paymentInfo?.received_security_deposit) ? "NA" : "₹" + currencyFormatter(paymentInfo?.received_security_deposit)}</div>
            </div>
            <div>
                <div className="info-text-label">Total Processing Fee</div>
                <div className="info-text-data">{_.isNil(paymentInfo?.total_processing_fee) ? "NA" : "₹" + currencyFormatter(paymentInfo?.total_processing_fee)}</div>
            </div>
            <div>
                <div className="info-text-label">Received Processing Fee</div>
                <div className="info-text-data">{_.isNil(paymentInfo?.received_processing_fee) ? "NA" : "₹" + currencyFormatter(paymentInfo?.received_processing_fee)}</div>
            </div>
        </div>

        {/* Tranche Details Form */}
        { (isForm) &&
        <Card style={{marginTop: 10}}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ marginTop: 10, fontWeight: 500, fontSize: 14 }}>Tranche {paymentTrancheList?.length + 1}</div>
            </div>
            <Divider style={{ margin: "5px 0" }} />
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                style={{marginBottom: 20}}
                onValuesChange={handleValueChange}
            >
                <Col>
                    <Row style={{ flexFlow: "nowrap", gap: 10 }}>
                        <div style={{width: "33%", marginBottom: 24}}>
                        <Form.Item style={{ width: '100%', margin: 0 }}
                            label={<p className="description-label">Security Deposit Received</p>}
                            name="securityDepositReceived"
                        >
                            <InputNumber precision={2} value={securityDeposit} onChange={e => setSecurityDeposit(e)} style={{width: "100%"}} className="input-number-override" placeholder="Enter Security Deposit Received" />
                        </Form.Item>
                            <span style={{opacity: 0.4, fontSize: 12, color: `${Math.round(paymentInfo?.total_security_deposit || 0) - Math.round(paymentInfo?.received_security_deposit || 0) - (securityDeposit || 0) >= 0 ? 'black' : 'red'}`}}>Remaining Security Deposit: ₹ {currencyFormatter((paymentInfo?.total_security_deposit || 0) - (paymentInfo?.received_security_deposit || 0) - (securityDeposit || 0))}</span>
                        </div>
                        
                        <div style={{width: "33%", marginBottom: 24}}>
                        <Form.Item style={{ width: '100%', margin: 0 }}
                            label={<p className="description-label">Processing Fee Received</p>}
                            name="processingFeeReceived"
                        >
                            <InputNumber precision={2} value={processingFee} onChange={e => setProcessingFee(e)} style={{width: "100%"}} className="input-number-override" placeholder="Enter Processing Fee Received" />
                        </Form.Item>
                            <span style={{opacity: 0.4, fontSize: 12, color: `${Math.round(paymentInfo?.total_processing_fee || 0) - Math.round(paymentInfo?.received_processing_fee || 0) - (processingFee || 0) >= 0 ? 'black' : 'red'}`}}>Remaining Processing Fee Deposit: ₹ {currencyFormatter(paymentInfo?.total_processing_fee - paymentInfo?.received_processing_fee - (processingFee || 0))}</span>
                        </div>

                        <Form.Item style={{ width: '33%' }}
                            label={<p className="description-label">Down Payment</p>}
                            name="downPayment"
                        >
                            <InputNumber precision={2} style={{width: "100%"}} className="input-number-override" placeholder="Enter Down Payment" />
                        </Form.Item>
                    </Row>
                    <Row style={{ flexFlow: "nowrap", gap: 10 }}>
                        <Form.Item style={{ width: '33%' }}
                            label={<p className="description-label">UTR Number</p>}
                            name="utrNo"
                        >
                            <Input className="input-override" placeholder="Enter UTR Number" />
                        </Form.Item>
                        <Form.Item style={{ width: '33%' }}
                            label={<p className="description-label">Transaction Date</p>}
                            name="transactionDate"
                        >
                            <DatePicker style={{ width: "100%"}} className="date-picker-override" />
                        </Form.Item>
                        <Form.Item style={{ width: '33%' }}
                            label={<p className="description-label">No. of vehicle payment done for <span style={{opacity: 0.2}}>(Optional)</span></p>}
                            name="numberOfVehiclePaymentDoneFor"
                        >
                            <InputNumber style={{width: "100%"}} className="input-number-override" placeholder="Enter No. of vehicle payment done for" />
                        </Form.Item>
                    </Row>
                    <Row style={{ flexFlow: "nowrap", gap: 10 }}>
                        <Form.Item style={{ width: '100%' }}
                            label={<p className="description-label">Remarks</p>}
                            name="remarks"
                        >
                            <Input className="input-override" placeholder="Enter Remarks" />
                        </Form.Item>
                    </Row>
                    <Row>

                    </Row>
                    <Row>
                        <Form.List name="additionalCharges" initialValue={[{}]}>
                            {(fields, { add, remove }) => (
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}><p className="description-label">Additional Charges</p> <span style={{ cursor: "pointer", color: "#1677ff", fontWeight: 500, fontSize: 12, marginBottom: 20 }} onClick={() => add()}>+ Add More</span></div>

                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <div key={key} style={{ display: 'flex', marginBottom: 8, width: "100%" }}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'additionalChargesName']}
                                                    style={{ width: "100%", marginRight: 10 }}
                                                >
                                                    <Input className="input-override" placeholder="Enter Name" />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'additionalChargesValue']}
                                                    style={{ width: "100%", marginRight: 10 }}
                                                >
                                                    <InputNumber precision={2} style={{width: "100%"}} className="input-number-override" placeholder="Enter Value" />
                                                </Form.Item>
                                                <DeleteIcon style={{ width: "10%", marginTop: 10, cursor: "pointer" }} onClick={() => remove(name)} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </Form.List>
                    </Row>
                    <Row>
                        <Col style={{width: "100%"}}>
                            <p className="description-label">Proof of Payment Confirmation</p>
                            <Upload
                                accept=".docx,.doc,.pptx,.ppt,.xlsx,.xls,.pdf,.jpg,.jpeg,.png"
                                style={{ width: "100%", display: "block" }}
                                customRequest={(file) => handleUploadRequest({ file })}
                                beforeUpload={(file) => {
                                    handleUploadRequest({ file })
                                    return false
                                }}
                                fileList={fileList}
                                onRemove={handleDelete}
                                itemRender={(originNode, file, fileList2, actions) => <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: 8 }}>
                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <Typography.Text ellipsis>
                                            <Tooltip title={file.name}>
                                                {file.name}
                                            </Tooltip>
                                        </Typography.Text>
                                    </div>
                                    <div style={{ display: "flex", gap: 10 }}>
                                        {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.type as string) ? <div onClick={() => {
                                            const itemIndex = fileList2.findIndex((i) => i.uid === file.uid);
                                            setPreviewFile({
                                                "fileName": file.name,
                                                "type": file.type as string,
                                                "url": fileUrls[itemIndex],
                                            })
                                        }} style={{ cursor: "pointer" }}>
                                            <EyeOutlined />
                                        </div> :
                                            <EyeInvisibleFilled style={{ color: "grey" }} />
                                        }

                                        <Button disabled={uploading} onClick={actions.remove} size="small" type="text" style={{ cursor: "pointer" }}>
                                            <DeleteFilled style={{ color: "red" }} />
                                        </Button>
                                    </div>
                                </div>}
                                listType="text"
                                multiple={true}
                            >

                                <div style={{width: "100%"}}>
                                    <Button
                                        loading={uploading}
                                        disabled={uploading}
                                        size="large"
                                        block
                                        ghost
                                        type='primary'
                                        className='button-override primary'
                                        style={{ width: "100%" }}
                                        icon={<AddIcon />}
                                    >
                                        Upload
                                    </Button>
                                </div>

                            </Upload>
                        </Col>
                    </Row>
                </Col>

            </Form>
        </Card>
        }

        {/* Tranche Details List */}
        {
            paymentTrancheList && paymentTrancheList?.map((e: any, key: number) => {
                return <Card style={{margin: "10px 0"}}>
                <div style={{ marginTop: 20, fontWeight: 500, fontSize: 14 }}>{`Tranche ${key + 1}`}</div>
                <Divider style={{ margin: "5px 0" }} />
                    <Form
                        layout="vertical"
                        requiredMark={false}
                    >
                        <Col>
                            <Row style={{ flexFlow: "nowrap", gap: 10 }}>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '33%' }}
                                    label={<p className="description-label">Security Deposit Received</p>}
                                    name="securityDepositReceived"
                                >
                                    <Typography>{e?.securityDepositReceived}</Typography>
                                </Form.Item>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '33%' }}
                                    label={<p className="description-label">Processing Fee Received</p>}
                                    name="processingFeeReceived"
                                >
                                    <Typography>{e?.processingFeeReceived}</Typography>
                                </Form.Item>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '33%' }}
                                    label={<p className="description-label">Down Payment</p>}
                                    name="downPayment"
                                >
                                    <Typography>{e?.downPayment}</Typography>
                                </Form.Item>
                            </Row>
                            <Row style={{ flexFlow: "nowrap", gap: 10 }}>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '33%' }}
                                    label={<p className="description-label">UTR Number</p>}
                                    name="utrNo"
                                >
                                    <Typography>{e?.utrNo}</Typography>
                                </Form.Item>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '33%' }}
                                    label={<p className="description-label">Transaction Date</p>}
                                    name="transactionDate"
                                >
                                    <Typography>{dayjs(e?.transactionDate).format("DD MMM YYYY")}</Typography>
                                </Form.Item>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '33%' }}
                                    label={<p className="description-label">No. of vehicle payment done for</p>}
                                    name="numberOfVehiclePaymentDoneFor"
                                >
                                    <Typography>{e?.numberOfVehiclePaymentDoneFor}</Typography>
                                </Form.Item>
                            </Row>
                            <Row style={{ flexFlow: "nowrap", gap: 10 }}>
                                <Form.Item labelCol={{style: {padding: 0}}} style={{ width: '100%' }}
                                    label={<p className="description-label">Remark</p>}
                                    name="remarks"
                                >
                                    <Typography>{e?.remarks}</Typography>
                                </Form.Item>
                            </Row>
                            <Row>
                            </Row>
                            <Typography className="description-label">Additional Charges</Typography>
                            {e?.additionalCharges?.length !== 0 && <Row>
                                { e?.additionalCharges.map((e: any) => {
                                    return <Row style={{display: "flex", flexDirection: "column", margin: "10px 10px 10px 0"}}>
                                        <Space style={{border: "1px solid #00000033", borderRadius: 4, padding: 10}}>
                                            <Typography>{e?.additionalChargesName}</Typography>
                                            <Typography>₹ {e?.additionalChargesValue}</Typography>
                                        </Space>
                                    </Row>
                                })}
                            </Row>}
                            <Row>
                                <Typography className="description-label">Proof of Payment Confirmation</Typography>
                                {
                                    e?.clientFileDetails.map((file: any) => {
                                        return <Col span={24}>
                                            <Space>
                                                <p>{file.name}</p>
                                                <div style={{ display: "flex", gap: 10 }}>
                                                    {["application/pdf", "image/jpeg", "image/jpg", "image/png"].includes(file.fileType as string) ? <div onClick={() => {
                                                        setPreviewFile({
                                                            "fileName": file.name,
                                                            "type": file.fileType.split("/").pop() as string,
                                                            "url": file?.url,
                                                        })
                                                    }} style={{ cursor: "pointer" }}>
                                                        <EyeOutlined />
                                                    </div> :
                                                        <EyeInvisibleFilled style={{ color: "grey" }} />
                                                    }
                                                </div>
                                            </Space>
                                        </Col>
                                    })
                                }
                            </Row>
                        </Col>
                    </Form>
                </Card>
            })
        }

        <ViewDocumentModal setPreviewFile={setPreviewFile} previewFile={previewFile} />
        </div>
    </Modal>
}

export default PaymentModal;