import { Alert, Button, Card, Dropdown, message, Modal, Select, Spin, Tag, Typography, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { getRequest, getRequestForDownload, handleFileDownload, postRequest, putRequest } from "../../../../utils/handler/apiHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as TransferIcon } from '../../SVGs/transfer_icon.svg';
import { ReactComponent as DocumentIcon } from '../../SVGs/document_icon.svg';
import { ReactComponent as ReloadIcon } from '../../SVGs/reload_icon.svg';
import { ReactComponent as AddIcon } from '../../SVGs/add_icon.svg';
import { ReactComponent as RightArrowIcon } from '../../SVGs/right_arrow_icon.svg';
import { ReactComponent as DownIcon } from '../../Prospect/utils/SVGs/DownIcon.svg';
import { ReactComponent as EditIcon } from '../../Prospect/utils/SVGs/edit_icon.svg';
import { ReactComponent as HollowDownloadIcon } from '../../Prospect/utils/SVGs/HollowDownloadIcon.svg';
import { ReactComponent as CrossIcon } from '../../SVGs/cross_icon.svg';
import { ReactComponent as HoldIcon } from '../../SVGs/hold_icon.svg';
import { ReactComponent as TickIcon } from '../../SVGs/tick_icon.svg';
import { ReactComponent as DownloadIcon } from '../../SVGs/download_icon.svg';
import { ReactComponent as UploadIcon } from '../../SVGs/upload_icon.svg';
import { ReactComponent as EyeIcon } from './../../../../utils/icons/view_icon.svg';
import { ReactComponent as CycleIcon } from '../../SVGs/cycle_icon.svg';
import CreditAssessmentFormDetails from "./CreditAssessmentFormDetails";
import ParentAssessmentFrom from "./ParentAssessmentFrom";
import CreditRequestFormComponent from "../../Prospect/components/CreditRequest/CreditRequestFormComponent";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import { useAppSelector } from "../../../../app/hooks";
import { authSelector } from "../../../../features/auth/authSlice";
import { MenuProps } from "antd/lib/menu";
import ReasonModal from "./ReasonModal";
import SanctionFormModal from "./SanctionFormModal";
import ETAModal from "../../Quote/components/ETAModal";
import SanctionPreviewModal from "./SanctionPreviewModal";
import ApproveRejectModal from "./ApproveRejectModal";
import { userSelector } from "../../../../features/auth/userSlice";
import ApprovalListModal from "./ApprovalListModal";
import _ from "lodash";
import RemarkModal from "./RemarkModal";
import getFormattedText from "../../../../utils/helpers/htmlToFormattedText";
import OnHoldRemarkModal from "./OnHoldRemarkModal";
import { getFormattedDate } from "../../../../utils/helpers/utils";
import CamViewModal from "./CamViewModal";
import CYADatePickerModal from "./CYADatePickerModal";

const antIcon = <LoadingOutlined style={{ fontSize: 16, color: "currentColor", marginLeft: 6 }} spin />;

interface CreditAssessmentFormLayoutProps {
    allowedActionsForProspect: Set<string>;
    view: boolean;
}

const CreditAssessmentFormLayout: React.FC<CreditAssessmentFormLayoutProps> = ({ view, allowedActionsForProspect }) => {
    const navigate = useNavigate();
    const auth = useAppSelector(authSelector);
    const unsupportedFileTypes = new Set(['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls', 'csv']);
    const [camFileFormatSupported, setCamFileFormatSupported] = useState<any>(true);
    const prospectId = useLocation().pathname.split("/").pop();
    const [apiToggle, setApiToggle] = useState<any>(false);
    const [data, setData] = useState<any>(null);
    const [FYCount, setFYCount] = useState<number>(2); // FYCount 1-6 (max 6)
    const [CYACount, setCYACount] = useState<number>(0);
    const [datePickerModal, setDatePickerModal] = useState<boolean>(false);
    const [dates, setDates] = useState<any>(null);
    const [changeFY, setChangeFY] = useState<boolean>(false); // calenderYear
    const [childView, setChildView] = useState<boolean>(true);
    const [creditFormModal, setCreditFormModal] = useState<boolean>(false);
    const [isParentAssessment, setIsParentAssessment] = useState<boolean>(false);
    const [creditRequestData, setCreditRequestData] = useState<any>();
    const [assessmentFormFlag, setAssessmentFormFlag] = useState<number>(1);
    const [showMoveToCommitteeReview, setShowMoveToCommitteeReview] = useState<boolean> (false);
    const [showDownloadCAM, setShowDownloadCAM] = useState<boolean> (false);
    const [showDownloadSanctionLetter, setShowDownloadSanctionLetter] = useState<boolean> (false);
    const [showEditDetail, setShowEditDetail] = useState<boolean> (false);
    const [submitForm, setSubmitForm] = useState<boolean> (false);
    const [reloadComponentCnt, setReloadComponentCnt] = useState<number> (0);
    const [editClickedCnt, setEditClickedCnt] = useState<number> (0);
    const [uploadCamModal, setUploadCamModal] = useState<boolean>(false)
    const [viewCamModal, setViewCamModal] = useState<boolean>(false)
    const [uploadLoader, setUploadLoader] = useState<boolean>(false);
    const [blob, setBlob] = useState<any>(null);
    const [generateCam, setGenerateCam] = useState<boolean>(false)
    const [holdLoader, setHoldLoader] = useState<boolean>(false);
    const [holdModal, setHoldModal] = useState<boolean>(false);
    const [onHoldRemarkModal, setOnHoldRemarkModal] = useState<boolean>(false);
    const [file, setFile] = useState<any>(null);
    const [parentAssessmentFromFlag, setParentAssessmentFromFlag] = useState<boolean>(false);
    const [showMarkPending, setShowMarkPending] = useState<boolean>(false);
    const [showNoGo, setShowNoGo] = useState<boolean>(false);
    const [showSanctionFrom, setShowSanctionFrom] = useState<boolean>(false);
    const [showCommitteeModal, setShowCommitteeModal] = useState<boolean>(false);
    const [showSanctionPreviewModal, setShowSanctionPreviewModal] = useState<boolean>(false);
    const [acceptRejectModal, setAcceptRejectModal] = useState<boolean>(false);
    const [remark, setRemark] = useState<string>("");
    const [onHoldRemark, setOnHoldRemark] = useState<string>("");
    const [committeeLoader, setCommitteeLoader] = useState<boolean>(false);
    const [creditRequestId, setCreditRequestId] = useState<any>(null);
    const [fileData, setFileData] = useState<any>(null);
    const [type, setType] = useState<boolean>(false);
    const [eta, setEta] = useState<string>("");
    const [showApprovalListModal, setShowApprovalListModal] = useState<boolean>(false);
    const [noteText, setNoteText] = useState<any>("");
    const [authorities, setAuthorities] = useState<any>([]);
    const [CYADateRanges, setCYADateRanges] = useState<any>([]);
    const [authority, setAuthority] = useState<any>({});
    const [viewSanctionLetter, setViewSanctionLetter] = useState<boolean>(false);
    const [generateSanctionFlag, setGenerateSanctionFlag] = useState<boolean>(false);
    const [reinitiateModal, setReinitiateModal] = useState<boolean>(false);
    const [reinitiateLoader, setReinitiateLoader] = useState<boolean>(false);
    const [onHoldRemarkLoader, setOnHoldRemarkLoader] = useState<boolean>(false);
    const [camDownloadLoader, setCamDownloadLoader] = useState<boolean>(false);
    const [reinitiateRemark, setReinitiateRemark] = useState<string>("");
    const [camLoader, setCamLoader] = useState<boolean>(false);
    const [reasonLoader, setReasonLoader] = useState<boolean>(false);
    const [camUploadLoader, setCamUploadLoader] = useState<boolean>(false);
    const user = useAppSelector(userSelector);
    const access = user.role === "GEVL-CREDIT-MANAGER" || user.role === "GEVL-CREDIT-HEAD" || user.role === "GEVL-ADMIN" || user.role === "GEVL-RESTRICTED-ADMIN"; 
    useEffect(() => { 
        !_.isEmpty(data?.creditSanction?.creditSanctionApproval) && getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/sanctionApprovers?sanctionId=${data?.creditSanction.id}`).then(res => {
            let authorityList: any = [];
            let approvalCount=0;
            const sanctionData = res.data;
            sanctionData.map((e: any) => {
                authorityList.push(e.authority.authorityEmail)
                if(e.status === "Approved") {
                    approvalCount++;
                }
                if(user.email === e.authority.authorityEmail) setAuthority(e);
            });
            setGenerateSanctionFlag(approvalCount === authorityList.length);
            setAuthorities(authorityList);
    })}, [data]);

    console.info(`FYCount=%o`,FYCount)

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><ReloadIcon style={{marginRight: 8}}/>Reupload CAM</div>,
        },
        {
            key: '2',
            label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><DownloadIcon style={{marginRight: 8}}/>Download Template</div>,
        },
    ];

    const addFyItems = [
        {
            key: '1',
            label: FYCount < 5 ? <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><AddIcon style={{marginRight: 8}}/>Previous Year</div> : '',
    
        },
        {
            key: '2',
            label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><AddIcon style={{marginRight: 8}}/>Current Year</div>,
        },
    ];

    const menu = {
        items: addFyItems,
        onClick: (key : any) => {
            key.key === "1"
            ? setFYCount(FYCount+1)
            : setDatePickerModal(true);
        },
    };

    const viewCAMItems = [
        {
            key: '1',
            label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><EyeIcon style={{marginRight: 8}}/>View CAM</div>,
        },
        {
            key: '2',
            label: <div style={{color: "#1677ff", fontWeight: 500, fontSize: 12}}><DownloadIcon style={{marginRight: 8}}/>Download CAM</div>,
        },
    ];


    const viewCAMMenu = {
        items: viewCAMItems,
        onClick: (key : any) => {
            key.key === "1"
            ? handleViewCAMFile(data?.camDocId)
            : handleCAMFileDownload(data?.camDocId);
        },
    };

    useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                setData(res.data);
                setCreditRequestId(res.data?.creditRequestId)
            });
        } catch (err) {
            console.log(err);
        }
    }, [apiToggle]);
    
    useEffect(()=>{
        if(editClickedCnt!==0){
            setReloadComponentCnt(Math.random());
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2&child=${!parentAssessmentFromFlag}`);
            setTimeout(()=>window.location.reload(),1000);
        }
    },[editClickedCnt]);

    const handleEditClick = async()=>{
        const payload = {
            creditRequestId: data.creditRequestId,
            isParent: parentAssessmentFromFlag
        }
        try{
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/buttonFlags/${data.creditRequestId}?isParent=${parentAssessmentFromFlag}`, {}).then(res => {
                message.destroy();
            });
            setEditClickedCnt((oldVal:number)=>oldVal+1);
        }
        catch(err) {
            console.log("Error while handling Edit click : ", err);
        }
    }

    const handleMarkHold = () => {
        const payload = {
            statusChangeTypeId : data.creditRequestStatusMaster.id !== 3 ? 3 : 2,
        }
        setHoldLoader(true);
        putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
            setHoldLoader(false);
            setHoldModal(false);
            navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
            window.location.reload(); 
        })
    }
    
    const handleGenerateDownloadCAM = (num: number) => {
        try {
            setCamLoader(true);
            handleFileDownload(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/report/downloadCam?creditRequestId=${data?.creditRequestId}`, `CAM_${data?.creditRequestId}`, `docx`).then((res: any) => {
                message.destroy();
                console.log(res)
                if(res === 200) {
                    navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                    !num && setTimeout(() => window.location.reload(), 1000);
                    setGenerateCam(false);
                    message.success("CAM downloaded")
                    setCamLoader(false);
                } else {
                    message.error("CAM download failed")
                }
            })
        } catch (err) {
            console.log(err);
            message.error("CAM download failed")
        }
    }

    const handleUpload = async () => {
        if(file) {
            setUploadLoader(true);
            try {
                let bodyFormData = new FormData();
                bodyFormData.append('file', file);
                bodyFormData.append('prospectId', data.prospectId);
                bodyFormData.append('creditRequestId', data?.creditRequestId);
    
                setCamUploadLoader(true);
                const res = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/file/uploadCam`,
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data", "Authorization": ` Bearer ${auth.accessToken}` },
                    });

                putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, {
                    camDocId: res.data.DocumentUrl
                }).then(res => {
                    message.destroy();
                    if(res.status === 200) { 
                        message.success("CAM uploaded successfully");
                        setUploadLoader(false);
                        setUploadCamModal(false);
                        setCamUploadLoader(false);
                        navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                        setTimeout(() => window.location.reload(), 1000)
                        }
                })
            } catch (err: any) {
                console.log("Error: ", err);
                message.error(err?.response?.data?.message);
                setUploadLoader(false)
            }
        }
    }

    const downloadTemplate = async () => {
        try {
            const response = await fetch(data?.camDocId);
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const blobData: any = await response.blob();
            setBlob(blobData);
            
            // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
            const blobUrl = URL.createObjectURL(blobData);
            // Create a link element
            const link = document.createElement('a');
            // Set link's href to point to the Blob URL
            link.href = blobUrl;
            link.download = data?.camDocId.split("/").pop() || `CAM_${data?.creditRequestId}`;
            // Append link to the body
            document.body.appendChild(link);
            // Dispatch click event on the link
            // This is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(
                new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
                })
            );
            // Remove link from body
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error converting S3 PDF to Blob:', error);
        }
    }

    const onClick: MenuProps['onClick'] = ({ key }) => {
        ~~key === 1
        ? setUploadCamModal(true)
        : downloadTemplate()
    };

    const handleView = () => {
        data?.parentLegalBusinessName
        ? setParentAssessmentFromFlag(!parentAssessmentFromFlag)
        : setChildView(!childView);
    }


    const handleCommitteeEta = () => {
        try {
            const payload = {
                statusChangeTypeId : 4,
                moveToCommitteeEta: eta
            }
            setCommitteeLoader(true);
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
                setCommitteeLoader(false);
                setShowSanctionFrom(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            });
            console.log(payload);
        } catch(err) {
            console.log(err);
        }
    }

    const handleSanctionAction = (status: number) => {
        try {
            const payload = {
                statusChangeTypeId: status === 2 ? 5 : status === 3 ? 4 : 9,
                sanctionStatusId : status,
                sanctionRemark: noteText
            }
            console.log(payload);

            setReasonLoader(true);
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
                setReasonLoader(false);
                setShowSanctionFrom(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            });
        } catch(err) {
            console.log(err);
        }
    }

    const handleApprovalRejection = (statusId: number) => {
        try {
            const payload = {
                statusId,
                remark
            }
            setCommitteeLoader(true);
            authority?.id && putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/sanctionApprovalStatus/${authority?.id}?sanctionId=${data?.creditSanction?.id}&creditRequestId=${data?.creditRequestId}`, payload).then(res => {
                setCommitteeLoader(false);
                setShowSanctionFrom(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            });
            console.log(payload);
        } catch(err) {
            console.log(err);
        }
    }
    
    const handleSanctionDownload = () => {
        const uri: string = data?.creditSanction?.sanctionDocId as string;
        handleFileDownload(uri, `Sanction_Letter_${data?.creditSanction?.id}`, "pdf");
    }

    const handleViewCAMFile = (url: any) => {
        let urlSplit = url.split("/");
        let fileName = urlSplit[urlSplit.length-1];
        let fileFormatSplit = fileName.split(".");
        let fileFormat = fileFormatSplit[fileFormatSplit.length-1];
        setFileData({fileName : fileName, url : url});
        if(!unsupportedFileTypes.has(fileFormat)){
            setCamFileFormatSupported(true);
        }else{
            setCamFileFormatSupported(false);
        }
        setViewCamModal(true);
    }

    const handleReinitiate = (status: number) => {
        try {
            const payload = {
                statusChangeTypeId : status,
                statusChangeReason : reinitiateRemark
            };

            setReinitiateLoader(true);
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
                setReinitiateModal(false);
                setReinitiateLoader(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            });
        } catch(err) {
            console.log(err);
        }
    }

    const handleOnHoldRemark = (status: number) => {
        try {
            const payload = {
                statusChangeTypeId : status,
                onHoldRemark : onHoldRemark
            };

            setOnHoldRemarkLoader(true);
            putRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/${data?.creditRequestId}`, payload).then(res => {
                setOnHoldRemarkModal(false);
                setOnHoldRemarkLoader(false);
                navigate(`/dashboard/prospect/${prospectId}?tab=3&subTab=2`);
                window.location.reload();
            });
        } catch(err) {
            console.log(err);
        }
    }

    const handleCAMFileDownload = async (url: any) => {
        try {
            setCamDownloadLoader(true);
          const response = await fetch(url);
          if (!response.ok) {
            setCamDownloadLoader(false);
            throw new Error('Network response was not ok');
          }
          const blobData = await response.blob();
          const blobUrl = URL.createObjectURL(blobData);
          const link = document.createElement('a');
          link.href = blobUrl;

          let urlSplit = url.split("/");
          let fileName = urlSplit[urlSplit.length-1];

          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobUrl);
          setCamDownloadLoader(false);
        } catch (error) {
            setCamDownloadLoader(false);
          console.error('Error downloading file:', error);
        }
      };

    const handleRemoveCYA = () => {
        setCYACount(CYACount-1);
        setCYADateRanges(CYADateRanges.slice(1));
    };


    return ( 
    <React.Fragment>
    {data && <>
        <span style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            {!view && <span style={{display: "flex", alignItems: "center"}}>
                <Typography style={{fontSize: 14, fontWeight: 500}}>Credit Request</Typography>
                <Tag style={{marginLeft: 10, fontSize: 12, fontWeight: 500, color: data?.creditRequestStatusMaster?.color, backgroundColor: data?.creditRequestStatusMaster?.backgroundColor, border: "none"}}>{data.creditRequestStatusMaster?.name}</Tag>
            </span>}

            <div style={{display: "flex"}}>
            {
                   (data && access && data?.camDocId && data?.creditRequestStatusMaster.id === 4) && 
                    <Dropdown menu={viewCAMMenu} trigger={['click']}>
                    <Button
                    type="primary"
                    style={{fontWeight:'600', fontSize:'12px', marginLeft: 10, color:'#1777FF', backgroundColor: 'rgba(23, 119, 255, 0.10)', 
                    borderColor: '#1777FF'}}
                    className="button-override"
                    >
                        <div style={{display:'flex',}}>

                            <EyeIcon style={{marginTop:'2.5px', marginRight: 7, color:'#1777FF'}} />
                            <div style={{padding:'8px 10px 8px 0px' ,borderRight:"1px solid #1777FF", margin:'-8px 0px -8px 0px'}}>
                                View CAM
                            </div>
                            <DownIcon style={{marginTop:'6px',marginLeft: 10, color:'#1777FF'}} />
                        </div>
                    </Button>
                    </Dropdown>
            }

            <Button
                ghost
                type="primary"
                onClick={_=> {setCreditFormModal(true)}}
                icon={<DocumentIcon />}
                style={{marginLeft: 10}}
                className="button-override primary"
            >Show Credit Request Form
            </Button>
            {
            (data && (_.isNull(data?.bypassRemark))) && 
            <div style={{display: "flex"}}>
                {
                    data?.parentLegalBusinessName && 
                    <Button
                        ghost
                        type="primary"
                        onClick={_=> handleView()}
                        icon={<TransferIcon />}
                        style={{marginLeft: 10}}
                        className="button-override primary"
                    >{(!childView || parentAssessmentFromFlag) ? "Switch to Child View" : "Switch to Parent View"}
                    </Button>
                }
                { 
                (childView && assessmentFormFlag === 1 && !(data?.creditRequestStatusMaster?.id === 3)) &&
                <Button
                    ghost
                    type="primary"
                    disabled={!childView}
                    onClick={_=> setChangeFY(!changeFY)}
                    icon={<ReloadIcon />}
                    style={{marginLeft: 10}}
                    className={childView ? `button-override primary` : `button-override disable`}
                >{ changeFY ? "Change to Financial Year" : "Change to Calendar Year"}
                </Button>}
                
                {
                    (data && access && data.camDocId && 
                    data?.creditRequestStatusMaster?.id !== 11 
                    && data?.creditRequestStatusMaster?.id !== 6
                    && data?.creditRequestStatusMaster?.id !== 4
                    && data?.creditRequestStatusMaster?.id !== 9
                    ) &&
                    <Dropdown menu={{ items, onClick }} trigger={['click']}>
                    <Button
                    type="primary"
                    style={{fontWeight:'600', fontSize:'12px', marginLeft: 10, color:'#1777FF', backgroundColor: 'rgba(23, 119, 255, 0.10)', 
                    borderColor: '#1777FF'}}
                    className="button-override"
                    >
                        <div style={{display:'flex',}}>

                            <HollowDownloadIcon style={{marginTop:'2px', marginRight: 8, color:'#1777FF'}} />
                            <div style={{padding:'8px 10px 8px 0px' ,borderRight:"1px solid #1777FF", margin:'-8px 0px -8px 0px'}}>
                                Download CAM 
                            </div>
                            <DownIcon style={{marginTop:'6px',marginLeft: 10, color:'#1777FF'}} />
                        </div>
                    </Button>
                    </Dropdown>
                }

                {
                    false &&
                    <Button
                    type="primary"
                    onClick={_=> {console.log(`Will Download SanctionLetter`)}}
                    className="button-override primary"
                    >
                        <HollowDownloadIcon style={{paddingTop:'1px', marginRight: 8, color:'#1777FF'}} />
                        <span >
                        Download Sanction Letter
                        </span>
                        
                    </Button>
                }
                {(childView && FYCount >2  && assessmentFormFlag === 1) &&
                
                    <Button
                        ghost
                        danger
                        type="primary"
                        onClick={_=> setFYCount(FYCount-1)}
                        icon={<CrossIcon />}
                        style={{marginLeft: 10}}
                        className={!childView || FYCount <3  || assessmentFormFlag > 1 ? "button-override disable" : "button-override danger"}
                    >Remove Last FY
                    </Button>
                }
                {(childView && CYACount >0  && assessmentFormFlag === 1) &&
                
                <Button
                    ghost
                    danger
                    type="primary"
                    onClick={_=> handleRemoveCYA()}
                    icon={<CrossIcon />}
                    style={{marginLeft: 10}}
                    className={!childView || assessmentFormFlag > 1 ? "button-override disable" : "button-override danger"}
                >Remove Last CYA
                </Button>
                }
                {
                   (childView && assessmentFormFlag === 1 && !(data?.creditRequestStatusMaster?.id === 3)) &&
                    <Dropdown menu={menu} trigger={['click']}>
                    <Button
                    type="primary"
                    style={{fontWeight:'600', fontSize:'12px', marginLeft: 10, color:'#1777FF', backgroundColor: 'rgba(23, 119, 255, 0.10)', 
                    borderColor: '#1777FF'}}
                    className="button-override"
                    >
                        <div style={{display:'flex',}}>

                            <AddIcon style={{marginTop:'2.5px', marginRight: 7, color:'#1777FF'}} />
                            <div style={{padding:'8px 10px 8px 0px' ,borderRight:"1px solid #1777FF", margin:'-8px 0px -8px 0px'}}>
                                Add New FY
                            </div>
                            <DownIcon style={{marginTop:'6px',marginLeft: 10, color:'#1777FF'}} />
                        </div>
                    </Button>
                    </Dropdown>
                }
            </div>
            }
            </div>
        </span>
        {(data && (_.isNull(data?.bypassRemark) || data?.bypassRemark === ""))
        && <div>
            {childView
            ? <div>
                <Tag style={{marginTop: 10}} color={parentAssessmentFromFlag ? "green" : "magenta"}>{parentAssessmentFromFlag ? `${data?.parentLegalBusinessName?.name} - Parent Assessment`  : `${data?.legalBusinessName?.name} - Child Assessment` }</Tag>
                <CreditRequestFormComponent FYCount={FYCount} setFYCount={setFYCount} calenderYear={changeFY} setCalenderYear={setChangeFY} creditRequestId={data.creditRequestId} 
                    submitForm={submitForm} setSubmitForm={setSubmitForm} assessmentFormFlag={assessmentFormFlag} setAssessmentFormFlag={setAssessmentFormFlag}
                    setShowMoveToCommitteeReview = {setShowMoveToCommitteeReview} setShowDownloadCAM = {setShowDownloadCAM}
                    setShowDownloadSanctionLetter = {setShowDownloadSanctionLetter} setShowEditDetail={setShowEditDetail}
                    reloadComponentCnt={reloadComponentCnt} setReloadComponentCnt={setReloadComponentCnt} onHold = {data?.creditRequestStatusMaster?.id === 3}
                    parentAssessmentFromFlag={parentAssessmentFromFlag}
                    CYACount={CYACount}
                    setCYACount={setCYACount}
                    CYADateRanges={CYADateRanges}
                    setCYADateRanges={setCYADateRanges}
                />
                </div>
            : <ParentAssessmentFrom parentLegalBusiness={data?.parentLegalBusinessName} creditRequestId={data?.creditRequestId} setIsParentAssessment={setIsParentAssessment} setChildView={setChildView} />
        }
        </div>}

        {(data?.creditRequestStatusMaster.id === 3 && data?.onHoldAt) 
        && <Card bordered={false} style={{marginTop: 40, marginBottom : 15}}>
            <Typography style={{fontSize: 16, fontWeight: 500}}>On Hold Reason</Typography>
            <Typography style={{ fontSize: 12, fontWeight: 500 , paddingBottom : '5px', paddingTop : '5px'}}>
                          On Hold by : {data?.onHoldBy && JSON.parse(data?.onHoldBy).name} <span style={{color : '#B2B2B2', marginLeft : '5px', marginRight : '5px'}}> | 
                          </span> On Hold at : {data?.onHoldAt && getFormattedDate(data?.onHoldAt)}
                        </Typography>
            <Typography style={{ fontSize: 14, marginBottom: 5 }}><span style={{fontWeight : 500}}> Remark : </span> {data?.onHoldRemark} </Typography>
        </Card>}

        {(!_.isNull(data.bypassRemark) && data?.creditRequestStatusMaster.id >= 4) 
        && <Card bordered={false} style={{marginTop: 10}}>
            <Typography style={{fontSize: 16, fontWeight: 500}}>Assessment bypassed</Typography>
            <Typography>Bypass Reason: {data?.bypassRemark}</Typography>
        </Card>}
        {(!_.isNull(data.sanctionStatus) && data?.sanctionStatus === "No-Go") 
        && <Alert
            style={{marginTop: 10}}
            message="Rejection Reason"
            description={getFormattedText(data?.sanctionRemark)}
            type="error"
            showIcon
            /> }
        {(!_.isNull(data.sanctionStatus) && data?.sanctionStatus === "Decision Pending") 
        && <Alert
            style={{marginTop: 10}}
            message="Decision Pending"
            description={getFormattedText(data?.sanctionRemark)}
            type="warning"
            showIcon
            /> }
    
        {
        childView && 
        <div style={{display: "flex", justifyContent: "flex-end", marginTop: 10}}>
            {((data.creditRequestStatusMaster.id < 4) 
            && data.creditRequestStatusMaster.id !== 3
            && ((!parentAssessmentFromFlag && JSON.parse(data?.childCreditAssessment?.buttonFlagDetail)?.["Edit detail"] === 1)
            || (parentAssessmentFromFlag && JSON.parse(data?.parentCreditAssessment?.buttonFlagDetail)?.["Edit detail"] === 1)))
            &&  <Button
                type="primary"
                onClick={handleEditClick}
                style={{fontWeight:'600', fontSize:'12px' ,marginLeft: 10, color:'#1777FF', backgroundColor: 'rgba(23, 119, 255, 0.10)', 
                borderColor: '#1777FF'}}
                className="button-override"
            >
                <EditIcon style={{marginRight: 8}} />
                Edit Details
            </Button>}

            {((access 
            && data?.creditRequestStatusMaster?.id !== 3 
            && data?.creditRequestStatusMaster?.id <= 3 
            && !data?.parentLegalBusinessName) && data?.downloadCamCount < 1 
            && JSON.parse(data?.childCreditAssessment?.buttonFlagDetail)["Form flag"] ) 
            &&  <Button
                ghost
                type="primary"
                onClick={_=> handleView()}
                icon={<TransferIcon />}
                style={{marginLeft: 10}}
                className="button-override primary"
            >
                Add Parent
            </Button>}

            {(data 
            && access 
            && JSON.parse(data?.creditReqButtonFlag)?.["Move to committee review"] !== 1 
            && JSON.parse(data?.creditReqButtonFlag)?.["Mark on hold"] !== 0
            && data?.creditRequestStatusMaster?.id !== 10) 
            &&  <Button
                ghost={data.creditRequestStatusMaster.id === 3 ? false : true}
                type="primary"
                onClick={_=> data.creditRequestStatusMaster.id === 3 ?  setHoldModal(true) : setOnHoldRemarkModal(true)}
                icon={data.creditRequestStatusMaster.id !== 3 && <HoldIcon />}
                style={{marginLeft: 10}}
                className={ data.creditRequestStatusMaster.id === 3 ? "button-override" : "button-override secondary" }
            >
            { data.creditRequestStatusMaster.id === 3 ? "Continue Assessment Process" : "Mark On hold" }
            {data.creditRequestStatusMaster.id === 3 && <RightArrowIcon style={{marginLeft: 8}}/>}
            </Button>}

            {(data && access && data?.creditRequestStatusMaster?.id !==  3 && ((JSON.parse(data.childCreditAssessment.buttonFlagDetail)["Form flag"] < 4 && !parentAssessmentFromFlag && _.isNull(data.bypassRemark)) || (JSON.parse(data.parentCreditAssessment.buttonFlagDetail)["Form flag"] < 4 && parentAssessmentFromFlag))) &&
                <Button
                    type="primary"
                    onClick={_=> setSubmitForm(true)}
                    style={{marginLeft: 10}}
                    className="button-override"
                >
                    Proceed
                    <RightArrowIcon style={{marginLeft: 8}} />
                </Button>
            }
            {(data && access && JSON.parse(data?.creditReqButtonFlag)?.["Upload CAM"] === 1) &&
                <Button
                    ghost
                    type="primary"
                    onClick={_=> setUploadCamModal(true)}
                    icon={camUploadLoader ? <Spin indicator={antIcon} /> : <UploadIcon />}
                    style={{marginLeft: 10}}
                    className="button-override primary"
                >
                    Upload CAM
                </Button>
            }
            {(data 
            && access 
            && JSON.parse(data?.childCreditAssessment?.buttonFlagDetail)?.["Download CAM template"] === 1) 
            &&  <Button
                ghost
                type="primary"
                icon={<DownloadIcon />}
                onClick={_=> handleGenerateDownloadCAM(1)}
                style={{marginLeft: 10}}
                className="button-override primary"
            >
                Download CAM template
            </Button>}

            {(data 
            && access 
            && (JSON.parse(data?.creditReqButtonFlag)?.["Move to committee review"] !== 1 
            && JSON.parse(data?.childCreditAssessment?.buttonFlagDetail)?.["Generate and download CAM"] === 1 
            && data?.creditRequestStatusMaster?.id < 3 && JSON.parse(data?.childCreditAssessment?.buttonFlagDetail)["Form flag"] === 4 
            && (!_.isNull(data?.parentLegalBusinessName) ? data && JSON.parse(data?.parentCreditAssessment?.buttonFlagDetail)["Form flag"] === 4 : true))) 
            &&  <Button
                type="primary"
                style={{marginLeft: 10}}
                onClick={_=> {setGenerateCam(true)} }
                className="button-override"
            >
                {data?.downloadCamCount > 0 ? "Regenerate and download CAM" : "Generate and download CAM"}
                <RightArrowIcon style={{marginLeft: 8}} />
            </Button>}

            {(data 
            && access
            && JSON.parse(data?.creditReqButtonFlag)?.["Move to committee review"] === 1) 
            &&  <Button
                type="primary"
                onClick={_=> setShowCommitteeModal(true)}
                style={{marginLeft: 10}}
                className="button-override"
            >
                Move to Committee Review
                <RightArrowIcon style={{marginLeft: 8}} />
            </Button>}

            {(access && !generateSanctionFlag && data?.creditRequestStatusMaster?.id === 11) 
            &&  <Button
                ghost
                // disabled={true}
                type="primary"
                onClick={_=> setReinitiateModal(true)}
                style={{marginLeft: 10}}
                icon={<CycleIcon />}
                className="button-override primary"
            >
                Reinitiate Process
            </Button>}

            {(data?.creditRequestStatusMaster?.id === 6) 
            && <Button
                ghost
                type="primary"
                onClick={_=> {setShowSanctionPreviewModal(true); setViewSanctionLetter(true)}}
                style={{marginLeft: 10}}
                icon={<DocumentIcon />}
                className="button-override primary"
            >
                View Sanction Letter
            </Button>}
            { (data.creditRequestStatusMaster.id === 11 || data.creditRequestStatusMaster.id === 6) &&
            <>
            <Button
                ghost
                type="primary"
                onClick={_=> setShowApprovalListModal(true)}
                style={{marginLeft: 10}}
                icon={<EyeIcon />}
                className="button-override primary"
            >
                View Approvals
            </Button>
            {access && authority?.status === "Rejected" && <Button
                type="primary"
                onClick={_=> setShowSanctionPreviewModal(true)}
                style={{marginLeft: 10}}
                icon={<DocumentIcon />}
                className="button-override"
            >
                Review Draft Sanction Letter
            </Button>}
            </>
            }
            {generateSanctionFlag && 
            <Button
                type="primary"
                onClick={handleSanctionDownload}
                style={{marginLeft: 10}}
                icon={<DocumentIcon />}
                className="button-override"
            >
                Download Sanction Letter
            </Button>}
            { (access && authority?.id && authority?.status === "Pending" && data.creditRequestStatusMaster.id === 11) &&
            <Button
                type="primary"
                onClick={_=> setShowSanctionPreviewModal(true)}
                style={{marginLeft: 10}}
                icon={<DocumentIcon />}
                className="button-override"
            >
                Proceed to Approval
            </Button>
            }
            { (data.creditRequestStatusMaster.id === 4 || data.creditRequestStatusMaster.id === 9) &&
            <>
                {(access && data?.sanctionStatus !== "Decision Pending" && data?.sanctionStatus !== "No-Go") && <Button
                    ghost
                    type="primary"
                    onClick={_=> setShowMarkPending(true)}
                    style={{marginLeft: 10}}
                    className="button-override primary"
                >
                    Mark Decision Pending
                </Button>}
                {(access && data?.sanctionStatus !== "No-Go") && <Button
                    ghost
                    danger
                    type="primary"
                    onClick={_=> setShowNoGo(true)}
                    icon={<CrossIcon />}
                    style={{marginLeft: 10}}
                    className="button-override danger"
                >
                    No Go
                </Button>}
                {(access && data?.sanctionStatus !== "Decision Pending" && data?.sanctionStatus !== "No-Go") && <Button
                    type="primary"
                    onClick={_=> setShowSanctionFrom(true)}
                    style={{marginLeft: 10}}
                    icon={<TickIcon />}
                    className="button-override"
                >
                    Go
                </Button>}

                {(access && data?.sanctionStatus === "Decision Pending") && 
                <Button
                    type="primary"
                    onClick={_=> handleSanctionAction(3)}
                    style={{marginLeft: 10}}
                    icon={<TickIcon />}
                    className="button-override"
                >
                    Reinitiate process
                </Button>}
            </>
            }
        </div>
        }
    </>}
    <Modal
        title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>Upload CAM</Typography>}
        centered
        closable={true}
        open={uploadCamModal}
        onCancel={() => setUploadCamModal(false)}
        afterClose={() => setFile(null)}
        footer={<>
            <div className="edit-section">
                <Button
                    ghost
                    type="primary"
                    disabled={file === null}
                    onClick={handleUpload}
                    className={file === null ? "button-override disable" : "button-override primary"}
                    icon={uploadLoader ? <Spin indicator={antIcon} /> : <UploadIcon />}
                    >
                    Upload
                </Button>
            </div>
        </>}
        className="preview-modal"
    >
        <Dragger
            maxCount={1}
            fileList={file && [file]}
            beforeUpload={(file) => { 
                const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/msword']
                setFile(file); 
                const isDocx = allowedFileTypes.includes(file.type);
                if (!isDocx) {
                    message.error('This file format is not accepted!');
                    setFile(null);
                }
                return isDocx || Upload.LIST_IGNORE;
            }}
        >
            
            <p className="ant-upload-drag-icon">
            <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-text">Format accepted: </p>
            <Tag>pdf</Tag>
            <Tag>doc</Tag>
            <Tag>docx</Tag>
        </Dragger>
    </Modal>

    <Modal
        title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>Credit Request Form</Typography>}
        centered
        closable={true}
        open={creditFormModal}
        onCancel={() => setCreditFormModal(false)}
        footer={<></>}
        width={"80%"}
        className="preview-modal"
    >
        <CreditAssessmentFormDetails />
    </Modal>

    <Modal 
        title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>{`Are you sure you want to ${ data?.creditRequestStatusMaster?.id === 3 ? "continue assessment process" : "hold"} this credit assessment?`}</Typography>}
        centered
        closable={false}
        open={holdModal}
        onCancel={() => setHoldModal(false)}
        footer={<>
            <Button
                ghost
                danger
                icon={<CrossIcon />}
                onClick={_=>setHoldModal(false)}
                className="button-override danger"
            >
                Cancel
            </Button>
            <Button
                ghost
                type="primary"
                onClick={ handleMarkHold }
                icon={holdLoader ? <Spin indicator={antIcon} /> : <TickIcon />}
                className="button-override primary"
            >
                Confirm
            </Button>
        </>}
        className="preview-modal"
    />
    <Modal
        title={<Typography style={{background: "#F6FAFF", fontSize: 16, fontWeight: 500, color: "#404040", marginBottom: 20}}>{"Credit Assessment Memo"}</Typography>}
        centered
        closable={true}
        open={generateCam}
        onCancel={() => setGenerateCam(false)}
        footer={<>
            <Button
                type="primary"
                style={{padding: "10px 20px"}}
                onClick={_=> handleGenerateDownloadCAM(0) }
                className="button-override"
                icon={camLoader ? <Spin indicator={antIcon} /> : <TickIcon />}
            >
                Ok
            </Button>
        </>}
        className="preview-modal"
    >
        <Typography>A template for credit assessment memo has been downloaded on your system. You will have to upload the updated memo to continue the process.</Typography>
    </Modal>

    <ReasonModal 
        title="Enter Decision Pending Reason"
        buttonText="Mark Decision Pending"
        showModal={showMarkPending}
        setShowModal={setShowMarkPending}
        handler={()=> handleSanctionAction(1)}
        loader={reasonLoader}
        type="pending"
        setNoteText={setNoteText}
        noteText={noteText}
    />
    <ReasonModal 
        title="Reason for No-Go"
        buttonText="Mark No-Go"
        showModal={showNoGo}
        setShowModal={setShowNoGo}
        handler={()=> handleSanctionAction(2)}
        loader={reasonLoader}
        type="noGo"
        setNoteText={setNoteText}
        noteText={noteText}
    />
    <SanctionFormModal 
        showModal={showSanctionFrom} 
        setShowModal={setShowSanctionFrom} 
        data={data}
        setData={setData}
    />
    <ETAModal showModal={showCommitteeModal} setShowModal={setShowCommitteeModal} title="Committee Review ETA" subTitle="Enter the time, it will take you to complete the committee review" eta={eta} setEta={setEta} handler={handleCommitteeEta} etaLoader={committeeLoader} buttonText="Start Committee Review" />
    <SanctionPreviewModal view={data?.creditRequestStatusMaster?.id === 6 ? true : false} flow={"approved"} showModal={showSanctionPreviewModal} setShowModal={setShowSanctionPreviewModal} setType={setType} data={data} setAcceptRejectModal={setAcceptRejectModal} />
    <ApproveRejectModal type={type} showModal={acceptRejectModal} setShowModal={setAcceptRejectModal} remark={remark} setRemark={setRemark} handler={handleApprovalRejection} loader={false} />
    <ApprovalListModal showModal={showApprovalListModal} setShowModal={setShowApprovalListModal} data={data} />
    <RemarkModal title="Reason for reinitiating the process" subTitle="Enter reason for reinitiating" btnText="Reinitiate" loader={reinitiateLoader} showModal={reinitiateModal} setShowModal={setReinitiateModal} remark={reinitiateRemark} setRemark={setReinitiateRemark} handler={() => handleReinitiate(4)} type="reinitiate" />
    <OnHoldRemarkModal title="Reason for On-hold the process" subTitle="Enter reason for On-hold" loader={onHoldRemarkLoader} showModal={onHoldRemarkModal} setShowModal={setOnHoldRemarkModal} remark={onHoldRemark} setRemark={setOnHoldRemark} handler={() => handleOnHoldRemark(3)} />
    <CamViewModal fileData={fileData} showModal={viewCamModal} setShowModal={setViewCamModal} supportedFormat={camFileFormatSupported} handler={(url) => {handleCAMFileDownload(url);}} loader={camDownloadLoader}/>
    <CYADatePickerModal title="Start & End Date for Current Year Assessment" showModal={datePickerModal} setShowModal={setDatePickerModal} CYACount={CYACount} setCYACount={setCYACount} CYADateRanges={CYADateRanges} setCYADateRanges={setCYADateRanges} dates={dates} setDates={setDates} />
    </React.Fragment>
    );
}

export default CreditAssessmentFormLayout;