import { Button, Modal, Space } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { store } from "../../../../app/store";
import { DownloadOutlined } from "@ant-design/icons";

interface PropType {
    clientQuote?: any;
    visible: boolean,
    setVisible: (t: boolean) => void,
    handleDownload: (file: any) => void
}

export default function ViewBlobModal({ clientQuote, visible, setVisible, handleDownload }: PropType) {
    const [pdfUrl, setPdfUrl] = useState(null);
    const accessToken = store.getState().auth.accessToken;


    useEffect(() => {
        if (visible && clientQuote) {
            clientQuote?.ocUrl ? setPdfUrl(clientQuote?.ocUrl) : fetchPdf();
        }
    }, [clientQuote, accessToken, visible]);

    const fetchPdf = async () => {
        try {
            // Make the request to fetch the PDF file using Axios
            const response = await axios.get(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/quote/report/generateOcf/${clientQuote?.clientQuoteId}`, {
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",  // You can remove Content-Type if not needed
                },
                responseType: 'arraybuffer', // This ensures that the response is in binary format
            });

            // Create a Blob from the arraybuffer response
            const blob = new Blob([response.data], { type: 'application/pdf' });

            // Create a temporary URL for the Blob
            const pdfBlobUrl: any = URL.createObjectURL(blob);

            // Set the URL to state to display in the iframe
            setPdfUrl(pdfBlobUrl);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    return (
        <Modal 
            width={800} 
            onCancel={() => setVisible(false)} 
            title="OC Document" open={visible} 
            footer={
                <Space>
                    <Button onClick={handleDownload} className="button-override primary" style={{marginLeft: 10}} icon={<DownloadOutlined />} type="primary" ghost>Download OC</Button>
                    <Button type="primary" 
                    onClick={() => setVisible(false)}>Close</Button>
                </Space>
            }
            >
            {pdfUrl ? (
                <iframe
                    src={pdfUrl}
                    width="100%"
                    height="600px"
                    title="PDF Viewer"
                ></iframe>
            ) : (
                <p>Loading PDF...</p>
            )}
        </Modal>
    )
}