import { PaymentRequestListType } from "./PaymentRequestListType";
import { useNavigate } from "react-router-dom";
import FilterDropdown from "../Client/FilterDropDown";
import { useEffect, useRef, useState } from "react";
import { handlePageChange, handlePageSizeChange } from "../Prospect/utils/paginationFunctions";
import { LeftOutlined, MinusOutlined, PlusCircleOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { getRequest } from "../../../utils/handler/apiHandler";
import { getFormattedDate } from "../../../utils/helpers/utils";
import dayjs from "dayjs";
import { ReactComponent as EyeIcon } from '../../../utils/icons/view_icon.svg';
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { Table, Spin, Tooltip, Select, Tag, Typography, Row, Button, Col, Input, Space, Checkbox, Tabs } from "antd";

const rotatedMinusIconStyle = {
    transform: 'rotate(90deg)',
};

interface propType {
    showConfirmed: boolean
}

const tableHeight = 600

export default function PartiallyPaid() {
    const defaultSort = "pcd.payment_confirmation_detail_id|DESC"
    const defaultFilter = ``
    const defaultParams = `&sort=${encodeURIComponent(defaultSort)}&filterAnd=${encodeURIComponent(defaultFilter)}`
    
    const tableRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [sortOrder, setSortOrder] = useState<string>(''); // Default sort
    const [clientNameOptions, setClientNameOptions] = useState([]);
    const [clientIdList, setClientIdList] = useState([]);
    const [ownerList, setownerList] = useState([]);
    const [contractList, setContractList] = useState([]);
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const defaultData: PaymentRequestListType[] = [];
    const [data, setData] = useState<PaymentRequestListType[]>(defaultData);


    const handlePageSizeChangeLocal = (current: number, size: number) => {
        handlePageSizeChange(current, size, setPageSize, setCurrentPage);
    };

    const handlePageChangeLocal = (page: number) => {
        handlePageChange(page, setCurrentPage);
    };

    useEffect(() => {
        fetchAllRequestedQuoteTableData();
    }, [currentPage, pageSize, sortOrder]);

    const fetchAllRequestedQuoteTableData = async (): Promise<void> => {
        try {
            setLoading(true);
            const getApi = `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/quoteservice/client-quote/partiallyPaidPayment?page=${currentPage}&size=${pageSize}${sortOrder ? sortOrder : defaultParams}`;
            const res = await getRequest(getApi);
            if (res.status === 200) {
                console.log("res data", res.data)
                setData(res.data.items);
                if ('totalPages' in res.data) {
                    setPageCount(res.data.totalPages);
                }
                if ('totalItems' in res.data) {
                    setTotalRecord(res.data.totalItems);
                }
            } else {
                console.error('Error fetching Requested Quotes Table List:', res.msg);
            }
        } catch (error) {
            console.error('Error fetching Requested Quotes data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getColumns = (): ColumnsType<any> => [
        {
            title: "Order ID",
            dataIndex: "orderid",
            key: "pcd.payment_confirmation_detail_id",
            sorter: { multiple: 1 },
        },
        {
            title: "Client Name",
            dataIndex: 'clientname',
            filters: clientNameOptions.map((option: any) => ({
                text: option.name,
                value: option.id,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name: any, page: any) => { return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/prospectNames?page=${page}&size=10&name=${name}` }}
                    setBusinessNameOptions={setClientNameOptions}
                />
            ),
            key: 'lem.id',
        },
        {
            title: "Client Owner",
            dataIndex: "clientowner",
            key: "JSON_VALUE(p.created_by, '$.ssoId')",
            filters: ownerList.map((option: any) => ({
                text: option.name,
                value: `"${option.ssoId}"`,
            })),
            filterIcon: <SearchOutlined />,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => (
                <FilterDropdown
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    filters={filters}
                    fetchApiUrl={(name: any, page: any) => { return `${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/contract/owners?page=${page}&size=10&name=${name}` }}
                    setBusinessNameOptions={setownerList}
                />
            ),
        },
        // {
        //     title: "Owned By",
        //     dataIndex: "ownedBy", //audit infio->updaed by
        //     key: "ownedBy",
        //     width: "10%",
        // },
        {
            title: "Amount Recieved",
            dataIndex: "amountreceived",
            key: "amountreceived",
            // sorter: { multiple: 2 },
        },
        {
            title: "Amount Pending",
            dataIndex: "amountpending",
            key: "amountpending",
            // sorter: { multiple: 2 },
        },
        {
            title: "Last Transaction Date",
            dataIndex: "lasttransactiondate",
            key: "lasttransactiondate",
            render: (date: string) => date ? getFormattedDate(date).split(",")[1].trim() : "",
        },
        {
            title: "Requested By",
            dataIndex: "requestedby",
            key: "requestedby",
        },
        {
            title: "Vehicle Requested",
            dataIndex: "vehiclerequested",
            key: "vehiclerequested",
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Tooltip title="View Details">
                    <a onClick={() => navigate(`/dashboard/payments/${record?.orderid}?quoteId=${record.orderid}`)}>
                        <EyeIcon style={{ marginRight: '0.6rem' }} /> View Details
                    </a>
                </Tooltip>
            ),
            fixed: "right"
        },
        // {
        //     title: "Requested At",
        //     dataIndex: "requestedat",
        //     key: "requestedat",
        //     render: (date: string) => date ? getFormattedDate(date) : "",
        // },
        // {
        //     title: 'Status',
        //     dataIndex: 'quoteStatus',
        //     key: 'quoteStatus',
        //     render: (status, record: any) => record?.status && <Tag style={{color: record?.statustextcolor, backgroundColor: record?.statusbgcolor, border: "none"}}>{record?.status}</Tag>,
        // },
        // {
        //     title: "Action",
        //     key: "action",
        //     render: (text, record) => (
        //         <Tooltip title="View Details">
        //             <a onClick={() => navigate(`${record.clientQuoteId}`)}>
        //                 <EyeIcon style={{ marginRight: '0.6rem' }} /> View Details
        //             </a>
        //         </Tooltip>
        //     ),
        //     fixed: "right"
        // },
    ];


    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: SorterResult<PaymentRequestListType> | SorterResult<PaymentRequestListType>[]
    ) => {
        let sortString = '';
        let filterString = ""

        if (Array.isArray(sorter)) {
            // Handle multiple sorts
            sortString = sorter
                .filter(s => s.order) // Filter out columns without sorting
                .map(s => `${s.field}|${s.order === 'ascend' ? "ASC" : "DESC"}`) // Map each sorter to the format field:asc/desc
                .join(',');
        } else if (sorter.order) {
            // Handle single sort
            const sortField = sorter.field
            const sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
            sortString = `${sortField}|${sortOrder}`;
        }
        console.log("prospect filter", filters)
        if (sortString && sortString !== "") {
            sortString = "&sort=" + encodeURIComponent(sortString + "&" + defaultSort)
        } else {
            sortString = "&sort=" + encodeURIComponent(defaultSort)
        }
        
        
        const filterEntries = Object.entries(filters)
            .filter(([key, value]) => Array.isArray(value) && value.length > 0) // Ensure it's a valid array with values
            .map(([key, values]:any) => values.map((v: string | number) => `${key}|eq|${v}`).join('&')) // Format each filter entry
            .join('&'); // Combine all filters

        if (filterEntries) {
            filterString = `&filterOr=${encodeURIComponent(filterEntries + "&" + defaultFilter)}`;
        } else {
            filterString = `&filterOr=${encodeURIComponent(defaultFilter)}`;
        }
        console.log("filterString", filterString)

        // Set the sort order string for the API call
        setSortOrder(sortString + filterString);
    };

    return (
        <>

            <div style={{ backgroundColor: '#F6FAFF', height: '100%' }}>
                <div ref={tableRef} style={{ height: 0.80 * tableHeight }}>
                    <Spin spinning={loading} size="large">
                        <Table
                            className="lev-table"
                            columns={getColumns()}
                            dataSource={data || []}
                            pagination={{
                                pageSize: pageSize,
                                position: ["bottomRight"],
                                showSizeChanger: false,
                                showTotal: (total, range) => (
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', marginTop: '-4px' }}>
                                        <div style={{ fontSize: '1rem' }}>
                                            <span style={{ fontSize: 14, fontWeight: 400 }}>Showing</span>
                                            <Select defaultValue={10} style={{ width: 60, margin: '0 5px' }} onChange={(value) => handlePageSizeChangeLocal(1, value)}>
                                                <Select.Option value="10">10</Select.Option>
                                                <Select.Option value="20">20</Select.Option>
                                                <Select.Option value="50">50</Select.Option>
                                                <Select.Option value="100">100</Select.Option>
                                            </Select>
                                            <span style={{ fontSize: 15, fontWeight: 400 }}>per page</span>
                                            <span style={{ fontSize: 15, fontWeight: 400, marginRight: '10px', marginLeft: '10px', color: 'grey' }}><MinusOutlined style={rotatedMinusIconStyle} /></span>
                                            <span style={{ fontSize: 14, fontWeight: 400 }}>{`Results: ${range[0]}-${range[1]} of ${total}`}</span>
                                        </div>
                                    </div>
                                ),
                                onChange: handlePageChangeLocal,
                                current: currentPage,
                                total: totalRecord,
                                itemRender: (current, type, originalElement) => {
                                    if (type === 'prev') {
                                        return <LeftOutlined style={{ marginLeft: '4rem', fontSize: 12, fontWeight: 500 }} />;
                                    }
                                    if (type === 'next') {
                                        return <RightOutlined style={{ fontSize: 12, fontWeight: 500 }} />;
                                    }
                                    if (type === 'page') {
                                        return (
                                            <span style={{ cursor: 'pointer', fontSize: 12, fontWeight: 500 }} onClick={() => handlePageChangeLocal(current)}>
                                                {current}
                                            </span>
                                        );
                                    }
                                    if (type === 'jump-next' || type === 'jump-prev') {
                                        return <a style={{ color: 'black', fontSize: 12, fontWeight: 500 }}><strong> ... </strong></a>;
                                    }
                                    return originalElement;
                                },
                            }}
                            onChange={handleTableChange} // Handle sort
                            scroll={{ x: 1700, y: 0.80 * tableHeight }}
                            size="middle"
                        // components={{
                        //     header: {
                        //       wrapper: (props: any) => <thead style={{ backgroundColor: '#CEE6FA', height: '3.5rem' }}>{props.children}</thead>,
                        //     },
                        //   }}
                        />
                    </Spin>
                </div>
            </div>
        </>
    )
}